import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../contextProviders/ContextProvider";

export function Model_180400_110033(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/18/180400_110033.glb"
  );

  const { index } = props;
  const { elementsMaterials } = useGlobalContext();

  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  return (
    <group {...props} dispose>
      <mesh
        geometry={nodes.brep_1.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        height={1.10033}
        name={"wange"}
        index={index}
        scale={0.001}
      />
    </group>
  );
}

const imagepaths = {
  "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/01_standard_weiss.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/02_vulkanschwarz.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/03_cuvo.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/03_cuvo.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/04_sandbeige.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/04_sandbeige.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/05_nussbaum_optik.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/05_nussbaum_optik.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/06_eiche_astig_optik.jpg":
    process.env.PUBLIC_URL +
    "/images/materialsPreview/06_eiche_astig_optik.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/07_lichtgrau.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/07_lichtgrau.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/08_antrazithgrau.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/08_antrazithgrau.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/09_eichefurnier.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/09_eichefurnier.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/10_nussbaumfurnier.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/10_nussbaumfurnier.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/11_alpin_weiss.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/11_alpinweiss.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/12_schwarz.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/12_schwarz.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/13_seidengrau.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/13_seidengrau.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/14_quarzgrau.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/14_quarzgrau.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/15_perlkupfer.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/15_perlkupfer.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/16_fernblau.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/16_fernblau.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/17_rapsgelb.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/17_raspgelb.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/18_resedagruen.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/18_resedagruen.jpg",

  "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg":
    process.env.PUBLIC_URL + "/images/materialsPreview/19_aluminium.jpg",
};

export default imagepaths;

import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import sizesMap from "./sizesMap";
import * as THREE from "three";

const Seitenwangen = ({ modelRefs }) => {
  const [firstRowIntersections, setFirstRowIntersections] = useState();
  const [originalPosition, setOriginalPosition] = useState();

  const {
    elementsRendering,
    elements,
    setElements,
    setElementsMaterials,
    elementsMaterials,
    elementToAdd,
    setElementToAdd,
    setElementAdded,
    heightControls,
    completeWidth,
    raycasterRowIntersections,
    setRaycasterRowIntersections,
    setIsHeightEqual,
    sceneRerenders,
    setTotalHeight,
    setElementToDelete,
    setIndexOfModel,
    setAxes,
    setShowSidebar,
    viewportWidth,
    globalSeitenwangenTexture,
    wizardPreset,
    setWizardPreset,
    setLoading,
  } = useGlobalContext();

  // Get the intersections
  useEffect(() => {
    if (wizardPreset) {
      setOriginalPosition(
        parseFloat(-1 - sizesMap[elementToAdd]?.width / 2 - 0.019 / 2)
      );
    }
    if (heightControls.length && !wizardPreset) {
      setTimeout(() => {
        let intersectionsContainer = [];
        for (const raycasterRow of heightControls) {
          const intersectionHeights = raycasterRow?.intersectObjects(
            modelRefs.current,
            true
          );
          intersectionsContainer.push(intersectionHeights);
        }

        setRaycasterRowIntersections(intersectionsContainer);
      }, [500]);
    } else {
      // delete all elements and materials
      if (elements.length <= 2) {
        setElements([]);
        setElementsMaterials([]);
      }
    }
  }, [sceneRerenders, heightControls, elementsRendering]);

  // Effect to find out, if all row are of the same height
  useEffect(() => {
    if (!wizardPreset) {
      const filteredIntersections = raycasterRowIntersections.filter(
        (arr) => arr.length > 0
      );

      if (filteredIntersections.length !== 0) {
        const distances = filteredIntersections.map((arr) =>
          arr[arr.length - 1].distance.toFixed(1)
        );
        const areDistancesEqual = distances.every(
          (value, index, array) => value === array[0]
        );

        if (areDistancesEqual) {
          setIsHeightEqual(true);
        } else {
          setIsHeightEqual(false);
        }
        if (raycasterRowIntersections[0]) {
          setFirstRowIntersections(raycasterRowIntersections[0]);
        }
      }
    }
  }, [raycasterRowIntersections]);

  // Effect to find the right Seitenwangen based on the height of the first row
  useEffect(() => {
    if (!wizardPreset) {
      let lastLeftRowHeightValue = raycasterRowIntersections[0]?.length - 1;
      if (raycasterRowIntersections[0]) {
        if (raycasterRowIntersections[0][lastLeftRowHeightValue]) {
          let targetHeight =
            raycasterRowIntersections?.[0]?.[lastLeftRowHeightValue]?.distance +
            0.06;
          setTotalHeight((targetHeight + 0.02).toFixed(2));

          let closestObject = null;
          let minDifference = Infinity;

          for (const objectName in sizesMap) {
            if (objectName.startsWith("Model_17")) {
              const height = sizesMap[objectName].height;
              const difference = Math.abs(targetHeight - height);

              // Check if this object's height is closer than the previous closest object
              if (difference < minDifference) {
                closestObject = objectName;
                minDifference = difference;
              }
            }
          }

          let extension;
          let closestObjectExtenstion = null;
          let minDifferenceExtension = Infinity;

          if (targetHeight > 2.8) {
            const leftWangenIndex = elements.findIndex((obj) =>
              obj.model.startsWith("Model_17")
            );

            extension = targetHeight - 2.72;

            for (const objectName in sizesMap) {
              if (objectName.startsWith("Model_17")) {
                const height = sizesMap[objectName].height;
                const difference = Math.abs(extension - height);

                // Check if this object's height is closer than the previous closest object
                if (difference < minDifferenceExtension) {
                  closestObjectExtenstion = objectName;
                  minDifferenceExtension = difference;
                }
              }
            }
          }

          let rightWangeExtension;

          if (closestObjectExtenstion) {
            rightWangeExtension = closestObjectExtenstion?.replace(
              /Model_17/g,
              "Model_18"
            );
          }

          const rightWange = closestObject?.replace(/Model_17/g, "Model_18");

          if (elements.length < 3 && closestObject) {
            setOriginalPosition(
              parseFloat(-1 - sizesMap[elementToAdd]?.width / 2 - 0.019 / 2)
            );
            setElements((prevElements) => [
              ...prevElements,
              {
                x: -1 - sizesMap?.[elementToAdd]?.width / 2 - 0.019 / 2,
                y: -0.95 + sizesMap?.[elementToAdd]?.height / 2,
                z: -0.5,
                model: closestObject,
                griffleiste: false,
                sockel: false,
              },
              {
                x: -1 + sizesMap?.[elementToAdd]?.width / 2 + 0.019 / 2,
                y: -0.95 + sizesMap?.[elementToAdd]?.height / 2,
                z: -0.5,
                model: rightWange,
                griffleiste: false,
                sockel: false,
              },
            ]);
            setElementsMaterials((prevElementsMaterials) => [
              ...prevElementsMaterials,
              {
                front: null,
                korpus: globalSeitenwangenTexture,
                griffleiste: false,
                sockel: false,
                wange: true,
              },
              {
                front: null,
                korpus: globalSeitenwangenTexture,
                griffleiste: false,
                sockel: false,
                wange: true,
              },
            ]);
          } else {
            const elementsCopy = [...elements];
            if (closestObjectExtenstion) {
              // Code for setting the base Seitenwange left
              const replacementWangeLeft = {
                x: originalPosition,
                y: -0.95 + sizesMap[closestObject]?.height / 2 - 0.04,
                z: -0.5,
                model: closestObject,
                griffleiste: false,
                sockel: false,
              };
              const indexOfLeftWange = elements.findIndex((obj) =>
                obj.model.startsWith("Model_17")
              );
              elementsCopy[indexOfLeftWange] = replacementWangeLeft;

              // Code for setting the base Seitenwange right

              const replacementWangeRight = {
                x: originalPosition + completeWidth + 0.019,
                y: -0.95 + sizesMap[closestObject]?.height / 2 - 0.04,
                z: -0.5,
                model: rightWange,
                griffleiste: false,
                sockel: false,
              };
              const indexOfRightWange = elements.findIndex((obj) =>
                obj.model.startsWith("Model_18")
              );
              elementsCopy[indexOfRightWange] = replacementWangeRight;

              // Code for setting the Seitenwange extension left

              const replacementWangeLeftExtension = {
                x: originalPosition,
                y:
                  -0.95 +
                  targetHeight -
                  sizesMap[closestObjectExtenstion]?.height / 2 -
                  0.015,
                z: -0.5,
                model: closestObjectExtenstion,
                griffleiste: false,
                sockel: false,
              };

              let lastIndexOfLeftExtension;
              let generateNewExtension = false;
              let baseWangenIndexLeft = elements.findIndex((element) =>
                element.model.startsWith("Model_17")
              );

              for (let i = 0; i < elements.length; i++) {
                if (elements[i].model.startsWith("Model_17")) {
                  lastIndexOfLeftExtension = i;
                  if (i === baseWangenIndexLeft) {
                    lastIndexOfLeftExtension = elements.length;
                    generateNewExtension = true;
                  } else {
                    lastIndexOfLeftExtension = i;
                    generateNewExtension = false;
                  }
                }
              }

              elementsCopy[lastIndexOfLeftExtension] =
                replacementWangeLeftExtension;

              // Code for setting the Seitenwange extension right

              const replacementWangeRightExtension = {
                x: originalPosition + completeWidth + 0.019,
                y:
                  -0.95 +
                  targetHeight -
                  sizesMap[closestObjectExtenstion]?.height / 2 -
                  0.015,
                z: -0.5,
                model: rightWangeExtension,
                griffleiste: false,
                sockel: false,
              };

              let lastIndexOfRightExtension;
              let baseWangenIndexRight = elements.findIndex((element) =>
                element.model.startsWith("Model_18")
              );

              for (let i = 0; i < elements.length; i++) {
                if (elements[i].model.startsWith("Model_18")) {
                  lastIndexOfRightExtension = i;
                  if (i === baseWangenIndexRight) {
                    lastIndexOfRightExtension = elements.length + 1;
                  } else {
                    lastIndexOfLeftExtension = i;
                  }
                }
              }

              elementsCopy[lastIndexOfRightExtension] =
                replacementWangeRightExtension;

              if (generateNewExtension) {
                setElementsMaterials((prevElementsMaterials) => [
                  ...prevElementsMaterials,
                  {
                    front: null,
                    korpus: globalSeitenwangenTexture,
                    griffleiste: false,
                    sockel: false,
                    wange: true,
                  },
                  {
                    front: null,
                    korpus: globalSeitenwangenTexture,
                    griffleiste: false,
                    sockel: false,
                    wange: true,
                  },
                ]);
              }
            } else {
              const replacementWangeLeft = {
                x: originalPosition,
                y: -0.95 + sizesMap[closestObject]?.height / 2 - 0.04,
                z: -0.5,
                model: closestObject,
                griffleiste: false,
                sockel: false,
              };
              const indexOfLeftWange = elements.findIndex((obj) =>
                obj.model.startsWith("Model_17")
              );
              elementsCopy[indexOfLeftWange] = replacementWangeLeft;

              const replacementWangeRight = {
                x: originalPosition + completeWidth + 0.019,
                y: -0.95 + sizesMap[closestObject]?.height / 2 - 0.04,
                z: -0.5,
                model: rightWange,
                griffleiste: false,
                sockel: false,
              };
              const indexOfRightWange = elements.findIndex((obj) =>
                obj.model.startsWith("Model_18")
              );
              elementsCopy[indexOfRightWange] = replacementWangeRight;

              let leftWangenExtensionToDeleteArray = [];

              for (let i = 0; i < elements.length; i++) {
                if (elements[i].model.startsWith("Model_17")) {
                  leftWangenExtensionToDeleteArray.push(i);
                }
              }

              let rightWangenExtensionToDeleteArray = [];

              for (let i = 0; i < elements.length; i++) {
                if (elements[i].model.startsWith("Model_18")) {
                  rightWangenExtensionToDeleteArray.push(i);
                }
              }

              if (leftWangenExtensionToDeleteArray.length > 1) {
                let elementsMaterialsCopy = [...elementsMaterials];
                let allIndicesToDelete = [];
                allIndicesToDelete.push(leftWangenExtensionToDeleteArray[1]);
                allIndicesToDelete.push(rightWangenExtensionToDeleteArray[1]);

                allIndicesToDelete.sort((a, b) => b - a);

                for (const index of allIndicesToDelete) {
                  elementsCopy.splice(index, 1);
                  elementsMaterialsCopy.splice(index, 1);
                }
                setElementToDelete(allIndicesToDelete);
                if (allIndicesToDelete.length) {
                  // Iterate in reverse order to avoid reordering indices
                  for (let i = allIndicesToDelete.length - 1; i >= 0; i--) {
                    const index = allIndicesToDelete[i];
                    const modelToRemove = modelRefs.current[index];
                    if (modelToRemove) {
                      modelToRemove.traverse((child) => {
                        if (child instanceof THREE.Mesh) {
                          child.geometry.dispose();
                          if (child.material.map) {
                            child.material.map.dispose();
                          }
                          child.material.dispose();
                        }
                      });
                    }
                  }
                }
                setElementsMaterials(elementsMaterialsCopy);
              }
            }
            setElements(elementsCopy);
          }
        }
      }
    }
    setElementToAdd("");
    setElementAdded("");
    setWizardPreset(false);
    setIndexOfModel(null);
    setTimeout(() => {
      setLoading(false);
    }, [500]);
    if (viewportWidth < 1400) {
      setShowSidebar(false);
    }

    if (elements.length > 1) {
      setAxes(null);
    } else {
      setAxes("start");
    }
  }, [firstRowIntersections]);
  return <></>;
};

export default Seitenwangen;

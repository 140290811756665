import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../../contextProviders/ContextProvider";

export function Model_110110_600x734_fb(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/11/01/110110_600x734_fb.glb"
  );
  const { index } = props;
  const { elementsMaterials, showFronten } = useGlobalContext();

  const frontTexture = useTexture(elementsMaterials[index]?.front);
  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  frontTexture.colorSpace = THREE.SRGBColorSpace;
  frontTexture.needsUpdate = true;

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  /* Texture Loader hier hin */

  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        <mesh
          geometry={nodes.brep_11.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_13.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_5.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_7.geometry}
          material={materials.Scharniere}
        />
      </group>
      <mesh
        geometry={nodes.brep_25.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        scale={0.001}
      />
      {showFronten ? (
        <mesh
          geometry={nodes.brep_1.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
        />
      ) : (
        <mesh
          geometry={nodes.brep_1.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
          visible={false}
        />
      )}
    </group>
  );
}
useGLTF.preload("/images/HAL_9999_Loading-Animation_3.gif");

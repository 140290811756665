import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../../contextProviders/ContextProvider";

export function Model_110111_600x734_fb_led(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/11/01/110111_600x734_fb_led.glb"
  );

  const { index } = props;
  const { elementsMaterials, showFronten } = useGlobalContext();

  const frontTexture = useTexture(elementsMaterials[index]?.front);
  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  frontTexture.colorSpace = THREE.SRGBColorSpace;
  frontTexture.needsUpdate = true;

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  const ledMaterial = new THREE.MeshStandardMaterial({
    emissive: new THREE.Color(0xffe2c5),
    emissiveIntensity: 2,
  });

  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        {showFronten ? (
          <mesh
            geometry={nodes.brep_1.geometry}
            material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          />
        ) : (
          <mesh
            geometry={nodes.brep_1.geometry}
            material={new THREE.MeshStandardMaterial({ map: frontTexture })}
            visible={false}
          />
        )}
        <mesh
          geometry={nodes.brep_23.geometry}
          material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        />
        <mesh geometry={nodes.brep_2.geometry} material={ledMaterial} />
        <mesh geometry={nodes.brep_3.geometry} material={ledMaterial} />
        <mesh
          geometry={nodes.brep_10.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_14.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_16.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_8.geometry}
          material={materials.Scharniere}
        />
      </group>
    </group>
  );
}

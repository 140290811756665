import React, { Suspense } from "react";
import imagepaths from "../imagepaths/imagepaths";
import styles from "../../styles/configurator.module.scss";
import LoadingAnimation from "./LoadingObserver";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import { allFrontenMaterialsArray } from "../textures/materialsArrays";

const AllFrontenMaterials = ({ changeTexture }) => {
  const {
    selectedAll,
    frontenTexture,
    korpusTexture,
    globalFrontenTexture,
    globalKorpusTexture,
    globalGriffleistenTexture,
    globalSockelTexture,
    globalSeitenwangenTexture,
    isSelectedElement,
    indexOfModel,
  } = useGlobalContext();

  return (
    <>
      {imagepaths && (
        <>
          {allFrontenMaterialsArray.map((material, index) => (
            <div key={index}>
              <img
                src={material.path}
                alt={material.alt}
                onClick={() => changeTexture(material.texture)}
                className={`${styles.pointer} 
              ${
                selectedAll &&
                isSelectedElement === "Fronten" &&
                globalFrontenTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                selectedAll &&
                isSelectedElement === "Korpus" &&
                globalKorpusTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                selectedAll &&
                isSelectedElement === "Griffleisten" &&
                globalGriffleistenTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                selectedAll &&
                isSelectedElement === "Sockel" &&
                globalSockelTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                selectedAll &&
                isSelectedElement === "Seitenwangen" &&
                globalSeitenwangenTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                indexOfModel !== undefined &&
                !selectedAll &&
                isSelectedElement === "Fronten" &&
                frontenTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                indexOfModel !== undefined &&
                !selectedAll &&
                isSelectedElement === "Korpus" &&
                korpusTexture === material.texture
                  ? styles.selectedMaterial
                  : ""
              }`}
              />
              <p>{material.caption}</p>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AllFrontenMaterials;

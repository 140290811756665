import sizesMap from "../configurator/sizesMap";

function sockelHelper(
  intersectionSockel,
  elementToAdd,
  elements,
  elementsMaterials,
  boundingBox
) {
  let matchingSockel;
  let correctSockel;
  let totalSockelWidth = 0;
  let mustDeleteOtherSockel = false;
  let sumOfSockel;
  let indicesSockel = [];
  let indicesToDelete = [];

  var updatedSockelArray = [];
  var updatedSockelMaterialsArray = [];

  intersectionSockel.sort((a, b) => a.distance - b.distance);

  if (intersectionSockel.length) {
    for (const obj of intersectionSockel) {
      if (obj?.object?.name !== "wange") {
        if (obj?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7) {
          totalSockelWidth += obj?.object?.width;
          indicesToDelete.push(obj?.object?.index);
        } else {
          break;
        }
      }
    }

    sumOfSockel =
      parseFloat(totalSockelWidth.toFixed(1)) +
      parseFloat(sizesMap[elementToAdd]?.width.toFixed(1));

    matchingSockel = Object.keys(sizesMap).find((modelName) => {
      return (
        modelName.startsWith("Model_15") &&
        sizesMap[modelName].width === parseFloat(sumOfSockel.toFixed(1))
      );
    });

    if (matchingSockel) {
      mustDeleteOtherSockel = true;
      correctSockel = matchingSockel;
    } else {
      correctSockel = Object.keys(sizesMap).find((modelName) => {
        return (
          modelName.startsWith("Model_15") &&
          sizesMap[modelName].width === sizesMap[elementToAdd]?.width
        );
      });
    }
  } else {
    // code for non griffleisten intersections on the x axes
    correctSockel = Object.keys(sizesMap).find((modelName) => {
      return (
        modelName.startsWith("Model_15") &&
        sizesMap[modelName].width === sizesMap[elementToAdd]?.width
      );
    });
  }

  let correctXpositionOfSockel;
  if (mustDeleteOtherSockel) {
    correctXpositionOfSockel = parseFloat(
      boundingBox?.max?.x + sizesMap[elementToAdd]?.width - sumOfSockel / 2
    );
  } else {
    correctXpositionOfSockel = parseFloat(
      boundingBox?.max?.x + sizesMap[elementToAdd]?.width / 2
    );
  }

  indicesSockel = indicesToDelete;

  let result = [
    indicesSockel,
    matchingSockel,
    correctXpositionOfSockel,
    correctSockel,
  ];
  return result;
}

export default sockelHelper;

import React, { useEffect, useRef } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import sizesMap from "./sizesMap";

import * as THREE from "three";

const ModelPreview = () => {
  const { boundingBox, elementToAdd, axes, elements } = useGlobalContext();

  const meshRef = useRef();

  // Update the position and dimensions of the mesh and wireframe when the boundingBox changes
  useEffect(() => {
    if (meshRef.current && boundingBox) {
      let position = {
        x: 0,
        y: 0,
        z: 0,
      };

      switch (axes) {
        case "x":
          position = {
            x: boundingBox?.max?.x + sizesMap[elementToAdd].width / 2,
            y: parseFloat(
              boundingBox?.min?.y + sizesMap[elementToAdd].height / 2
            ).toFixed(5),
            z: -0.5,
          };
          break;

        case "y":
          position = {
            x: boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
            y: boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
            z: -0.5,
          };
          break;

        case "yLeft":
          position = {
            x: boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
            y: boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
            z: -0.5,
          };
          break;

        case "yDouble":
          position = {
            x: boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
            y: boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
            z: -0.5,
          };
          break;

        case "yRight":
          position = {
            x:
              (boundingBox?.min?.x + boundingBox?.max?.x) / 2 +
              sizesMap[elementToAdd].width / 2,
            y: boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
            z: -0.5,
          };
          break;

        case "":
          position = {
            x: -1,
            y: parseFloat(
              -0.95 + sizesMap[elementToAdd]?.height / 2 + 0.04 - 0.015
            ).toFixed(5),
            z: -0.5,
          };
          break;

        default:
          break;
      }
      meshRef.current.position.set(position.x, position.y, position.z);
    }
  }, [elementToAdd, axes]);

  return (
    elementToAdd &&
    elements.length > 3 &&
    axes !== null && (
      <group>
        <mesh ref={meshRef}>
          <boxGeometry
            args={[
              sizesMap?.[elementToAdd]?.width,
              sizesMap?.[elementToAdd]?.height,
              0.6,
            ]}
          />
          <meshBasicMaterial color="#ffff00" transparent opacity={0.2} />
        </mesh>
      </group>
    )
  );
};

export default ModelPreview;

import sizesMap from "../configurator/sizesMap";

function griffleistenHelper(
  leftSum,
  leftObjects,
  rightSum,
  rightObjects,
  elementToAdd,
  elements,
  elementsMaterials,
  boundingBox,
  axes
) {
  let matchingModel;
  var indices = [];

  var updatedElementsArray = [];
  var updatedMaterialsArray = [];

  let griffleistenArray = [];
  griffleistenArray = leftObjects;
  griffleistenArray = leftObjects.concat(rightObjects);

  if (typeof leftSum === "number") {
    leftSum = parseFloat(leftSum.toFixed(2));
  }

  if (typeof rightSum === "number") {
    rightSum = parseFloat(rightSum.toFixed(2));
  }

  let leftSumNumber = 0;
  for (let i = 0; i < leftSum.length; i++) {
    leftSumNumber += leftSum[i];
  }

  let rightSumNumber = 0;
  for (let i = 0; i < rightSum.length; i++) {
    rightSumNumber += rightSum[i];
  }

  let finalGriffleistenWidth =
    leftSumNumber + rightSumNumber + sizesMap[elementToAdd]?.width;
  finalGriffleistenWidth = parseFloat(finalGriffleistenWidth).toFixed(2);

  if (finalGriffleistenWidth > 2.7) {
    if (finalGriffleistenWidth - leftSum < 2.7) {
      finalGriffleistenWidth = leftSumNumber + sizesMap[elementToAdd]?.width;
      rightSum = [];
      rightSumNumber = 0;
      griffleistenArray = leftObjects;
    } else if (finalGriffleistenWidth - rightSum < 2.7) {
      finalGriffleistenWidth = rightSumNumber + sizesMap[elementToAdd]?.width;
      leftSum = [];
      leftSumNumber = 0;
      griffleistenArray = rightObjects;
    }
  }

  let correctGriffleiste;
  let mustDeleteOtherGriffleisten = false;

  // if Griffleisten are present to the left, calculate the sum of them + the width of the element to add
  if (finalGriffleistenWidth) {
    // find the new griffleiste with the right width
    matchingModel = Object.keys(sizesMap).find((modelName) => {
      return (
        modelName.startsWith("Model_16") &&
        sizesMap[modelName].width == finalGriffleistenWidth
      );
    });

    if (matchingModel) {
      // When a fitting griffleiste has been found, collect all indices of griffleisten to delete them afterwards
      griffleistenArray.forEach((obj) => {
        if (obj.hasOwnProperty("object")) {
          const index = obj.object.index;
          if (index !== undefined) {
            indices.push(index);
          }
        }
      });
      mustDeleteOtherGriffleisten = true;
      updatedElementsArray = elements.filter((element, index) => {
        return indices.includes(index);
      });

      updatedMaterialsArray = elementsMaterials.filter((element, index) => {
        return indices.includes(index);
      });

      correctGriffleiste = matchingModel;
    } else {
      // else just put the normal griffleiste on it
      correctGriffleiste = Object.keys(sizesMap).find((modelName) => {
        return (
          modelName.startsWith("Model_16") &&
          sizesMap[modelName].width === sizesMap[elementToAdd]?.width
        );
      });
    }
  } else {
    // code for non griffleisten intersections on the x axes
    correctGriffleiste = Object.keys(sizesMap).find((modelName) => {
      return (
        modelName.startsWith("Model_16") &&
        sizesMap[modelName].width === sizesMap[elementToAdd]?.width
      );
    });
  }

  let correctXpositionOfGriffleiste;

  if (axes === "y") {
    if (leftSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x - leftSumNumber + finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x - sizesMap[elementToAdd].width / 2;
      }
    } else if (!leftSum.length && rightSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x + rightSumNumber - finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x - sizesMap[elementToAdd].width / 2;
      }
    } else {
      correctXpositionOfGriffleiste =
        boundingBox?.max?.x - sizesMap[elementToAdd].width / 2;
    }
  }

  if (axes === "yLeft") {
    if (leftSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x - leftSumNumber + finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x - sizesMap[elementToAdd].width * 1.5;
      }
    } else if (!leftSum.length && rightSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x + finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x - sizesMap[elementToAdd].width * 1.5;
      }
    } else {
      correctXpositionOfGriffleiste =
        boundingBox?.max?.x - sizesMap[elementToAdd].width * 1.5;
    }
  }

  if (axes === "yRight") {
    if (leftSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          (boundingBox.max.x + boundingBox.min.x) / 2 -
          leftSumNumber +
          finalGriffleistenWidth * 0.5;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x + sizesMap[elementToAdd].width / 2;
      }
    } else if (!leftSum.length && rightSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          (boundingBox.max.x + boundingBox.min.x) / 2 +
          finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.max?.x - sizesMap[elementToAdd].width / 2;
      }
    } else {
      correctXpositionOfGriffleiste =
        (boundingBox.max.x + boundingBox.min.x) / 2 +
        sizesMap?.[elementToAdd]?.width / 2;
    }
  }

  if (axes === "yDouble") {
    if (leftSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x - leftSumNumber + finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x + sizesMap[elementToAdd].width / 2;
      }
    } else if (!leftSum.length && rightSum.length) {
      if (mustDeleteOtherGriffleisten) {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x + finalGriffleistenWidth / 2;
      } else {
        correctXpositionOfGriffleiste =
          boundingBox?.min?.x + sizesMap[elementToAdd].width / 2;
      }
    } else {
      correctXpositionOfGriffleiste = boundingBox?.max?.x;
    }
  }

  let result = [
    indices,
    matchingModel,
    correctXpositionOfGriffleiste,
    correctGriffleiste,
  ];
  return result;
}

export default griffleistenHelper;

import * as ModelComponents from "../models/index.js";

const modelMap = {
  Model_110110_600x734_fb: <ModelComponents.Model_110110_600x734_fb />,
  Model_110111_600x734_fb_led: <ModelComponents.Model_110111_600x734_fb_led />,

  Model_110210_600x493_fb: <ModelComponents.Model_110210_600x493_fb />,
  Model_110211_600x493_fb_led: <ModelComponents.Model_110211_600x493_fb_led />,

  Model_110310_600x1548_fb: <ModelComponents.Model_110310_600x1548_fb />,
  Model_110311_600x1548_fb_led: (
    <ModelComponents.Model_110311_600x1548_fb_led />
  ),
  Model_110320_600x1548_kl: <ModelComponents.Model_110320_600x1548_kl />,
  Model_110321_600x1548_kl_led: (
    <ModelComponents.Model_110321_600x1548_kl_led />
  ),
  Model_110330_600x1548_klf: <ModelComponents.Model_110330_600x1548_klf />,
  Model_110331_600x1548_klf_led: (
    <ModelComponents.Model_110331_600x1548_klf_led />
  ),

  Model_110410_400x734_fb: <ModelComponents.Model_110410_400x734_fb />,
  Model_110411_400x734_fb_led: <ModelComponents.Model_110411_400x734_fb_led />,

  Model_110510_400x1548_fb: <ModelComponents.Model_110510_400x1548_fb />,
  Model_110511_400x1548_fb_led: (
    <ModelComponents.Model_110511_400x1548_fb_led />
  ),

  Model_120110_600x734_fb: <ModelComponents.Model_120110_600x734_fb />,
  Model_120111_600x734_fb_led: <ModelComponents.Model_120111_600x734_fb_led />,

  Model_120210_600x493_fb: <ModelComponents.Model_120210_600x493_fb />,
  Model_120211_600x493_fb_led: <ModelComponents.Model_120211_600x493_fb_led />,

  Model_120310_600x1548_fb: <ModelComponents.Model_120310_600x1548_fb />,
  Model_120311_600x1548_fb_led: (
    <ModelComponents.Model_120311_600x1548_fb_led />
  ),
  Model_120320_600x1548_kl: <ModelComponents.Model_120320_600x1548_kl />,
  Model_120321_600x1548_kl_led: (
    <ModelComponents.Model_120321_600x1548_kl_led />
  ),
  Model_120330_600x1548_klf: <ModelComponents.Model_120330_600x1548_klf />,
  Model_120331_600x1548_klf_led: (
    <ModelComponents.Model_120331_600x1548_klf_led />
  ),

  Model_120410_400x734_fb: <ModelComponents.Model_120410_400x734_fb />,
  Model_120411_400x734_fb_led: <ModelComponents.Model_120411_400x734_fb_led />,

  Model_120510_400x1548_fb: <ModelComponents.Model_120510_400x1548_fb />,
  Model_120511_400x1548_fb_led: (
    <ModelComponents.Model_120511_400x1548_fb_led />
  ),

  Model_130110_600x19133_schub: (
    <ModelComponents.Model_130110_600x19133_schub />
  ),
  Model_130111_600x19133_schub_led: (
    <ModelComponents.Model_130111_600x19133_schub_led />
  ),

  Model_130210_1200x19133_schub: (
    <ModelComponents.Model_130210_1200x19133_schub />
  ),
  Model_130211_1200x19133_schub_led: (
    <ModelComponents.Model_130211_1200x19133_schub_led />
  ),

  Model_140110_300x2362_offen_fb: (
    <ModelComponents.Model_140110_300x2362_offen_fb />
  ),
  Model_140111_300x2362_offen_fb_led: (
    <ModelComponents.Model_140111_300x2362_offen_fb_led />
  ),

  Model_140210_300x21207_offen: (
    <ModelComponents.Model_140210_300x21207_offen />
  ),
  Model_140211_300x21207_offen_led: (
    <ModelComponents.Model_140211_300x21207_offen_led />
  ),

  Model_140310_600x4927_fb: <ModelComponents.Model_140310_600x4927_fb />,
  Model_140311_600x4927_fb_led: (
    <ModelComponents.Model_140311_600x4927_fb_led />
  ),

  Model_140410_600x2213_offen: <ModelComponents.Model_140410_600x2213_offen />,
  Model_140411_600x2213_offen_led: (
    <ModelComponents.Model_140411_600x2213_offen_led />
  ),

  Model_140510_1200x2213_offen: (
    <ModelComponents.Model_140510_1200x2213_offen />
  ),
  Model_140511_1200x2213_offen_led: (
    <ModelComponents.Model_140511_1200x2213_offen_led />
  ),

  Model_140610_400x1548_offen_fb: (
    <ModelComponents.Model_140610_400x1548_offen_fb />
  ),
  Model_140611_400x1548_offen_fb_led: (
    <ModelComponents.Model_140611_400x1548_offen_fb_led />
  ),

  Model_140710_400x12767_offen_fb: (
    <ModelComponents.Model_140710_400x12767_offen_fb />
  ),
  Model_140711_400x12767_offen_fb_led: (
    <ModelComponents.Model_140711_400x12767_offen_fb_led />
  ),

  Model_140810_400x764_offen_fb: (
    <ModelComponents.Model_140810_400x764_offen_fb />
  ),
  Model_140811_400x764_offen_fb_led: (
    <ModelComponents.Model_140811_400x764_offen_fb_led />
  ),

  Model_150100_300: <ModelComponents.Model_150100_300 />,
  Model_150200_400: <ModelComponents.Model_150200_400 />,
  Model_150300_600: <ModelComponents.Model_150300_600 />,
  Model_150400_900: <ModelComponents.Model_150400_900 />,
  Model_150500_1000: <ModelComponents.Model_150500_1000 />,
  Model_150600_1200: <ModelComponents.Model_150600_1200 />,
  Model_150700_1500: <ModelComponents.Model_150700_1500 />,
  Model_150800_1600: <ModelComponents.Model_150800_1600 />,
  Model_150900_1800: <ModelComponents.Model_150900_1800 />,
  Model_151000_2100: <ModelComponents.Model_151000_2100 />,
  Model_151100_2200: <ModelComponents.Model_151100_2200 />,
  Model_151200_2400: <ModelComponents.Model_151200_2400 />,
  Model_151300_2700: <ModelComponents.Model_151300_2700 />,

  Model_160100_300: <ModelComponents.Model_160100_300 />,
  Model_160200_400: <ModelComponents.Model_160200_400 />,
  Model_160300_600: <ModelComponents.Model_160300_600 />,
  Model_160400_900: <ModelComponents.Model_160400_900 />,
  Model_160500_1000: <ModelComponents.Model_160500_1000 />,
  Model_160600_1200: <ModelComponents.Model_160600_1200 />,
  Model_160700_1500: <ModelComponents.Model_160700_1500 />,
  Model_160800_1600: <ModelComponents.Model_160800_1600 />,
  Model_160900_1800: <ModelComponents.Model_160900_1800 />,
  Model_161100_2200: <ModelComponents.Model_161100_2200 />,
  Model_161200_2400: <ModelComponents.Model_161200_2400 />,
  Model_161300_2700: <ModelComponents.Model_161300_2700 />,

  Model_170100_28633: <ModelComponents.Model_170100_28633 />,
  Model_170200_55766: <ModelComponents.Model_170200_55766 />,
  Model_170300_829: <ModelComponents.Model_170300_829 />,
  Model_170400_110033: <ModelComponents.Model_170400_110033 />,
  Model_170500_137166: <ModelComponents.Model_170500_137166 />,
  Model_170600_1644: <ModelComponents.Model_170600_1644 />,
  Model_170700_191533: <ModelComponents.Model_170700_191533 />,
  Model_170800_218666: <ModelComponents.Model_170800_218666 />,
  Model_170900_2457: <ModelComponents.Model_170900_2457 />,
  Model_171000_272833: <ModelComponents.Model_171000_272833 />,

  Model_180100_28633: <ModelComponents.Model_180100_28633 />,
  Model_180200_55766: <ModelComponents.Model_180200_55766 />,
  Model_180300_829: <ModelComponents.Model_180300_829 />,
  Model_180400_110033: <ModelComponents.Model_180400_110033 />,
  Model_180500_137166: <ModelComponents.Model_180500_137166 />,
  Model_180600_1644: <ModelComponents.Model_180600_1644 />,
  Model_180700_191533: <ModelComponents.Model_180700_191533 />,
  Model_180800_218666: <ModelComponents.Model_180800_218666 />,
  Model_180900_2457: <ModelComponents.Model_180900_2457 />,
  Model_181000_272833: <ModelComponents.Model_181000_272833 />,
};
export default modelMap;

import React from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import imagepaths from "../imagepaths/imagepaths";
import styles from "../../styles/configurator.module.scss";

const ActiveMaterial = ({ element, texture }) => {
  const { setIsSelectedElement, isSelectedElement, setSelectedAll } =
    useGlobalContext();

  const handleElementSelection = (element) => {
    setIsSelectedElement(element);
    if (element === "Seitenwangen" || element === "Sockel") {
      setSelectedAll(true);
    }
  };

  return (
    <>
      {element === "Fronten" && (
        <div
          className={`${styles.pointer} ${styles.selectableElement} ${
            isSelectedElement === "Fronten" ? styles.selectedElement : ""
          }`}
          onClick={() => handleElementSelection("Fronten")}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/edit_front.svg"}
            alt="Fronten Textur"
          />
          <p>Fronten</p>
        </div>
      )}

      {element === "Korpus" && (
        <div
          onClick={() => handleElementSelection("Korpus")}
          className={`${styles.pointer} ${styles.selectableElement} ${
            isSelectedElement === "Korpus" ? styles.selectedElement : ""
          }`}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/edit_korpus.svg"}
            alt="Korpus Textur"
          />
          <p>Korpus</p>
        </div>
      )}

      {element === "Griffleisten" && (
        <div
          onClick={() => handleElementSelection("Griffleisten")}
          className={`${styles.pointer} ${styles.selectableElement} ${
            isSelectedElement === "Griffleisten" ? styles.selectedElement : ""
          }`}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/edit_griffleisten.svg"}
            alt="Griffleisten Textur"
          />
          <p>Griffleisten</p>
        </div>
      )}

      {element === "Sockel" && (
        <div
          onClick={() => handleElementSelection("Sockel")}
          className={`${styles.pointer} ${styles.selectableElement} ${
            isSelectedElement === "Sockel" ? styles.selectedElement : ""
          }`}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/edit_sockel.svg"}
            alt="Sockel Textur"
          />
          <p>Sockel</p>
        </div>
      )}

      {element === "Seitenwangen" && (
        <div
          onClick={() => handleElementSelection("Seitenwangen")}
          className={`${styles.pointer} ${styles.selectableElement} ${
            isSelectedElement === "Seitenwangen" ? styles.selectedElement : ""
          }`}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/edit_seiten.svg"}
            alt="Seitenwangen Textur"
          />
          <p>Seitenwangen</p>
        </div>
      )}
    </>
  );
};

export default ActiveMaterial;

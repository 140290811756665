import "./index.scss";
import {
  startTransition,
  Suspense,
  useRef,
  useState,
  useEffect,
  SyntheticEvent,
} from "react";

import { OrbitControls, useGLTF } from "@react-three/drei";
import Wizard from "./components/wizard/Wizard";
import Configurator from "./components/configurator/Configurator";
import { useGlobalContext } from "./contextProviders/ContextProvider";
import LoadingObserver from "./components/configurator/LoadingObserver";

const fetchSelection = async (id) => {
  try {
    const response = await fetch(`app-staging.4kantdesign.de/api/data/${id}`);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

function App() {
  const {
    state1,
    state2,
    wizardReady,
    setWizardReady,
    setWizardPreset,
    showSidebar,
    viewportWidth,
    setElements,
    setElementsMaterials,
    setElementsRendering,
    setHeightControls,
    setElementToAdd,
    setCompleteWidth,
    setTotalHeight,
    setIsHeightEqual,
    setAlreadyBought,
  } = useGlobalContext();

  useEffect(() => {
    // Function to make the API call
    const fetchData = async () => {
      let newElements = [];
      let newElementsMaterials = [];
      let newElementsRendering = [];
      let newHeightControls = [];
      let newElementToAdd = "";
      let newCompleteWidth = 0;
      let newTotalHeight = 0;

      try {
        // Extract the query parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const selectionParam = urlParams.get("selection");

        // Check if the parameter exists before making the API call
        if (selectionParam) {
          // Make the API call using the extracted parameter
          const response = await fetch(`api/data/${selectionParam}`);

          if (response.ok) {
            const dataArray = await response.json();

            if (dataArray.length > 0) {
              const data = dataArray[0];

              // Parse the stringified JSON fields
              data.heightControls = JSON.parse(data.heightControls);
              data.elements = JSON.parse(data.elements);
              data.elementsRendering = JSON.parse(data.elementsRendering);
              data.elementsMaterials = JSON.parse(data.elementsMaterials);

              newElements = data.elements;
              newElementsMaterials = data.elementsMaterials;
              newElementsRendering = data.elements;
              newHeightControls = data.heightControls;
              newElementToAdd = data.elementToAdd;
              newCompleteWidth = data.completeWidth;
              newTotalHeight = data.totalHeight;

              console.log(data);
            } else {
              console.error("Empty response array");
            }
          } else {
            console.error("Error fetching data:", response.statusText);
          }

          setElementToAdd(newElementToAdd);
          setElements(newElements);
          setElementsMaterials(newElementsMaterials);
          setElementsRendering(newElementsRendering);
          setCompleteWidth(newCompleteWidth);
          setTotalHeight(newTotalHeight);
          setAlreadyBought(true);
          setIsHeightEqual(true);
          setWizardPreset(true);
          setWizardReady(true);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    fetchData();
  }, []);

  // const [isAssetsLoading, setIsAssetsLoading] = useState(true);

  // useEffect(() => {
  //   // Listen for the window.onload event to detect when all assets are loaded
  //   window.addEventListener("load", handleLoad);
  //   console.log("loading");

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  // const handleLoad = () => {
  //   // All assets are loaded, so update the state
  //   console.log("loaded");
  //   setIsAssetsLoading(false);
  // };

  return (
    <div className="App">
      <img
        src={process.env.PUBLIC_URL + "/images/logo.png"}
        alt="Logo"
        className="logo"
      />

      {!wizardReady && <Wizard />}
      {wizardReady && (
        <>
          <Suspense>
            <Configurator />
          </Suspense>
        </>
      )}
    </div>
  );
}

export default App;

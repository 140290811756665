import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import styles from "../../styles/sidebar.module.scss";
import sizesMap from "./sizesMap";
import configuratorStyles from "../../styles/configurator.module.scss";

const Shelves = () => {
  const {
    elementToAdd,
    setElementToAdd,
    selectedModelWidth,
    axes,
    indexOfModel,
    setElementAdded,
  } = useGlobalContext();
  const [allowedWidth, setAllowedWidth] = useState();

  useEffect(() => {
    if (axes === "yLeft") {
      setAllowedWidth(selectedModelWidth * 0.5);
    }
    if (axes === "yRight") {
      let allowedWidths = [];
      allowedWidths.push(selectedModelWidth * 0.5);
      allowedWidths.push(selectedModelWidth);
      setAllowedWidth(allowedWidths);
    }
    if (axes === "yDouble") {
      setAllowedWidth(selectedModelWidth * 2);
    }
    if (axes === "y") {
      setAllowedWidth(selectedModelWidth);
    }
    if (axes === "x" || axes === null) {
      setAllowedWidth(0);
    }
  }, [selectedModelWidth, axes, indexOfModel]);

  return (
    <>
      {sizesMap["Model_140110_300x2362_offen_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140110_300x2362_offen_fb"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140110_300x2362_offen_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/30cm/offene_elemente/140110.jpg"
            }
            alt="Modell 140110"
          />
          <p>30cm x 239cm x 60cm </p>
          {elementToAdd === "Model_140110_300x2362_offen_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140110_300x2362_offen_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/30cm/offene_elemente/140110.jpg"
            }
            alt="Modell 140110"
          />
          <p>30cm x 239cm x 60cm </p>
        </div>
      )}

      {sizesMap["Model_140210_300x21207_offen"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140210_300x21207_offen"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140210_300x21207_offen")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/30cm/offene_elemente/140210.jpg"
            }
            alt="Modell 140210"
          />
          <p>30cm x 212cm x 60cm</p>
          {elementToAdd === "Model_140210_300x21207_offen" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140210_300x21207_offen");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/30cm/offene_elemente/140210.jpg"
            }
            alt="Modell 140210"
          />
          <p>30cm x 212cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_140510_1200x2213_offen"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140510_1200x2213_offen"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140510_1200x2213_offen")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/120cm/offene_elemente/140510.jpg"
            }
            alt="Modell 140510"
          />
          <p>120cm x 22cm x 60cm</p>
          {elementToAdd === "Model_140510_1200x2213_offen" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140510_1200x2213_offen");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/120cm/offene_elemente/140510.jpg"
            }
            alt="Modell 140510"
          />
          <p>120cm x 22cm x 60cm</p>
        </div>
      )}
    </>
  );
};

export default Shelves;

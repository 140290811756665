import { MeshStandardMaterial } from "three";

const weiß = new MeshStandardMaterial({
  map: "/images/Materials/01_standard_weiss.jpg",
});
const eiche_astig_optik = new MeshStandardMaterial({
  map: "/images/Materials/06_eiche_astig_optik.jpg",
});

export { weiß, eiche_astig_optik };

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../contextProviders/ContextProvider";

export function Model_160900_1800(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/16/160900_1800.glb"
  );

  const { index } = props;
  const { elementsMaterials } = useGlobalContext();

  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  return (
    <group {...props} dispose name={"griffleiste"}>
      <group scale={0.001} name={"griffleiste"}>
        <mesh
          geometry={nodes.brep_3.geometry}
          material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
          name={"griffleiste"}
          width={1.8}
          index={index}
        />
        <mesh
          geometry={nodes.brep_6.geometry}
          material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
          width={1.8}
          name={"griffleiste"}
          index={index}
        />
      </group>
    </group>
  );
}

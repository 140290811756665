import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import styles from "../../styles/sidebar.module.scss";
import sizesMap from "./sizesMap";
import configuratorStyles from "../../styles/configurator.module.scss";

const Cabinets = () => {
  const {
    elementToAdd,
    setElementToAdd,
    selectedModelWidth,
    axes,
    indexOfModel,
    setElementAdded,
  } = useGlobalContext();
  const [allowedWidth, setAllowedWidth] = useState();

  useEffect(() => {
    if (axes === "yLeft") {
      setAllowedWidth(selectedModelWidth * 0.5);
    }
    if (axes === "yRight") {
      let allowedWidths = [];
      allowedWidths.push(selectedModelWidth * 0.5);
      allowedWidths.push(selectedModelWidth);
      setAllowedWidth(allowedWidths);
    }
    if (axes === "yDouble") {
      setAllowedWidth(selectedModelWidth * 2);
    }
    if (axes === "y") {
      setAllowedWidth(selectedModelWidth);
    }
    if (axes === "x" || axes === null) {
      setAllowedWidth(0);
    }
  }, [selectedModelWidth, axes, indexOfModel]);

  return (
    <>
      {sizesMap["Model_110110_600x734_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110110_600x734_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110110_600x734_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110110.jpg"
            }
            alt="Modell 110110"
          />
          <p>60cm x 76cm x 60cm</p>
          {elementToAdd === "Model_110110_600x734_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110110_600x734_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110110.jpg"
            }
            alt="Modell 110110"
          />
          <p>60cm x 76cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_110210_600x493_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110210_600x493_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110210_600x493_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110210.jpg"
            }
            alt="Modell 110210"
          />
          <p>60cm x 49cm x 60cm</p>
          {elementToAdd === "Model_110210_600x493_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110210_600x493_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110210.jpg"
            }
            alt="Modell 110210"
          />
          <p>60cm x 49cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_110310_600x1548_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110310_600x1548_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110310_600x1548_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110310.jpg"
            }
            alt="Modell 110310"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Fachböden</p>
          {elementToAdd === "Model_110310_600x1548_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110310_600x1548_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110310.jpg"
            }
            alt="Modell 110310"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Fachböden</p>
        </div>
      )}

      {sizesMap["Model_110320_600x1548_kl"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110320_600x1548_kl"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110320_600x1548_kl")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110320.jpg"
            }
            alt="Modell 110320"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Kleiderstange</p>
          {elementToAdd === "Model_110320_600x1548_kl" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110320_600x1548_kl");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110320.jpg"
            }
            alt="Modell 110320"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Kleiderstange</p>
        </div>
      )}

      {sizesMap["Model_110330_600x1548_klf"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110330_600x1548_klf"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110330_600x1548_klf")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110330.jpg"
            }
            alt="Modell 110330"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Kleiderlift</p>
          {elementToAdd === "Model_110330_600x1548_klf" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110330_600x1548_klf");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/drehtueren/110330.jpg"
            }
            alt="Modell 110330"
          />
          <p>60cm x 158cm x 60cm</p>
          <p>Mit Kleiderlift</p>
        </div>
      )}

      {sizesMap["Model_140310_600x4927_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_140310_600x4927_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140310_600x4927_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/offene_elemente/140310.jpg"
            }
            alt="Modell 140310"
          />
          <p>60cm x 49cm x 60cm</p>
          {elementToAdd === "Model_140310_600x4927_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140310_600x4927_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/offene_elemente/140310.jpg"
            }
            alt="Modell 140310"
          />
          <p>60cm x 49cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_140410_600x2213_offen"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140410_600x2213_offen"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140410_600x2213_offen")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/offene_elemente/140410.jpg"
            }
            alt="Modell 140410"
          />
          <p>60cm x 22cm x 60cm</p>
          {elementToAdd === "Model_140410_600x2213_offen" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140410_600x2213_offen");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/offene_elemente/140410.jpg"
            }
            alt="Modell 140410"
          />
          <p>60cm x 22cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_110410_400x734_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110410_400x734_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110410_400x734_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/drehtueren/110410.jpg"
            }
            alt="Modell 110410"
          />
          <p>40cm x 76cm x 60cm</p>
          {elementToAdd === "Model_110410_400x734_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110410_400x734_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/drehtueren/110410.jpg"
            }
            alt="Modell 110410"
          />
          <p>40cm x 76cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_110510_400x1548_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(sizesMap["Model_110510_400x1548_fb"]?.width)) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_110510_400x1548_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/drehtueren/110510.jpg"
            }
            alt="Modell 110510"
          />
          <p>40cm x 158cm x 60cm</p>
          {elementToAdd === "Model_110510_400x1548_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_110510_400x1548_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/drehtueren/110510.jpg"
            }
            alt="Modell 110510"
          />
          <p>40cm x 158cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_140610_400x1548_offen_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140610_400x1548_offen_fb"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140610_400x1548_offen_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140610.jpg"
            }
            alt="Modell 140610"
          />
          <p>40cm x 158cm x 60cm</p>
          {elementToAdd === "Model_140610_400x1548_offen_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140610_400x1548_offen_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140610.jpg"
            }
            alt="Modell 140610"
          />
          <p>40cm x 158cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_140710_400x12767_offen_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140710_400x12767_offen_fb"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140710_400x12767_offen_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140710.jpg"
            }
            alt="Modell 140710"
          />
          <p>40cm x 131cm x 60cm</p>
          {elementToAdd === "Model_140710_400x12767_offen_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140710_400x12767_offen_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140710.jpg"
            }
            alt="Modell 140710"
          />
          <p>40cm x 131cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_140810_400x764_offen_fb"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_140810_400x764_offen_fb"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_140810_400x764_offen_fb")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140810.jpg"
            }
            alt="Modell 140810"
          />
          <p>40cm x 76cm x 60cm</p>
          {elementToAdd === "Model_140810_400x764_offen_fb" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_140810_400x764_offen_fb");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/40cm/offene_elemente/140810.jpg"
            }
            alt="Modell 140810"
          />
          <p>40cm x 76cm x 60cm</p>
        </div>
      )}
    </>
  );
};

export default Cabinets;

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../contextProviders/ContextProvider";

export function Model_151100_2200(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/15/151100_2200.glb"
  );

  const { index } = props;
  const { elementsMaterials } = useGlobalContext();

  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  return (
    <group {...props} dispose name={"sockel"}>
      <mesh
        geometry={nodes.brep_1.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        width={2.2}
        name={"sockel"}
        scale={0.001}
        index={index}
      />
    </group>
  );
}

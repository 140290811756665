import React, { useRef, useState, useEffect, startTransition } from "react";
import { Suspense } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, useHelper, Loader, Preload } from "@react-three/drei";

import styles from "../../styles/configurator.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import sidebarStyles from "../../styles/sidebar.module.scss";

import Sidebar from "./Sidebar";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import MaterialsOverview from "./MaterialsOverview";
import * as THREE from "three";
import { RepeatWrapping, Vector3, Raycaster } from "three";
import RaycasterHelper from "../helper/RaycasterHelper";
import ModelOutline from "./ModelOutline";
import { Perf } from "r3f-perf";
import SceneProperties from "./SceneProperties";
import ElementsDisplay from "./ElementsDisplay";
import sizesMap from "./sizesMap";
import SizesInformation from "./SizesInformation";
import LoadingObserver from "./LoadingObserver";
import LoadingAnimation from "./LoadingAnimation";
import Seitenwangen from "./Seitenwangen";
import ModelPreview from "./ModelPreview";
import Inventory from "../shopify/Inventory";
import Prices from "../shopify/Prices";
import StockOverview from "../shopify/StockOverview";

function Configurator(props) {
  const statsRef = useRef();
  const testRef = useRef();
  const sceneRef = useRef();
  const orbitRef = useRef();
  const modelRefs = useRef([]);
  const sidebarRef = useRef();
  const modelwrapperRef = useRef();
  const selectedModelRef = useRef();
  const [selectedModelIndex, setSelectedModelIndex] = useState(null);
  const [highlightedBox, setHighlightedBox] = useState(null);
  // const [firstRowIntersections, setFirstRowIntersections] = useState();
  // const [originalPosition, setOriginalPosition] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showBacktoWizardModal, setShowBackToWizardModal] = useState(false);
  const [showRefreshScene, setShowRefreshScene] = useState(false);

  const raycasterX = new Raycaster();
  raycasterX.far = 20;
  const raycasterXTop = new Raycaster();
  raycasterX.far = 20;
  const raycasterYleft = new Raycaster();
  raycasterYleft.far = 20;
  const raycasterYright = new Raycaster();
  raycasterYright.far = 20;
  const raycasterYrightDouble = new Raycaster();
  raycasterYright.far = 20;
  const raycasterYdouble = new Raycaster();
  raycasterYdouble.far = 20;
  const raycasterYpossible = new Raycaster();
  raycasterYpossible.far = 20;

  const raycasterXGriffleisten = new Raycaster();
  raycasterXGriffleisten.far = 20;
  const rayStartXGriffleisten = new Vector3(0, 0, 0);

  const rayStartX = new Vector3(0, 0, 0);
  const rayStartXTop = new Vector3(0, 0, 0);
  const rayStartYleft = new Vector3(0, 0, 0);
  const rayStartYright = new Vector3(0, 0, 0);
  const rayStartYrightDouble = new Vector3(0, 0, 0);
  const rayStartYdouble = new Vector3(0, 0, 0);
  const rayStartYpossible = new Vector3(0, 0, 0);

  const toTheRight = new Vector3(1, 0, 0);
  const toTheLeft = new Vector3(-1, 0, 0);
  const upwards = new Vector3(0, 1, 0);
  const downwards = new Vector3(0, -1, 0);

  const {
    showMaterialOverview,
    editMode,
    setEditMode,
    elements,
    setElements,
    axes,
    setHelperX,
    setHelperXTop,
    setHelperYleft,
    setHelperYright,
    setHelperYrightDouble,
    setHelperYdouble,
    setHelperYpossible,
    setAxes,
    showAxesModeX,
    setShowAxesModeX,
    showAxesModeY,
    setShowAxesModeY,
    showAxesModeYleft,
    setShowAxesModeYleft,
    showAxesModeYright,
    setShowAxesModeYright,
    showAxesModeYdouble,
    setShowAxesModeYdouble,
    setBoundingBox,
    setCurrentSelectedModelRef,
    setSelectedModelWidth,
    indexOfModel,
    setIndexOfModel,
    setElementToAdd,
    elementToDelete,
    setModelsArray,
    modelsArray,
    labelPosition,
    boundingBox,
    completeWidth,
    setCompleteWidth,
    elementsMaterials,
    setElementsMaterials,
    setElementToDelete,
    setElementsRendering,
    setTotalHeight,
    setHeightControls,
    viewportWidth,
    setViewportWidth,
    showSidebar,
    setShowSidebar,
    loading,
    setWizardReady,
    showFronten,
    setShowFronten,
    setLabelPosition,
    setSpaceUnderModel,
    alreadyBought,
  } = useGlobalContext();

  let boundingBoxGlobal = null;
  let doublePossible;

  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  useEffect(() => {
    let intersectsOnYleft = false;
    let intersectsOnYright = false;
    let intersectsOnYrightDouble = false;
    let intersectsOnYpossible = false;

    if (
      modelRefs.current[indexOfModel] &&
      elements[indexOfModel].model.startsWith("Model_15") === false &&
      elements[indexOfModel].model.startsWith("Model_17") === false &&
      elements[indexOfModel].model.startsWith("Model_18") === false
    ) {
      const bbox = new THREE.Box3();
      bbox.setFromObject(modelRefs.current[indexOfModel]);
      setCurrentSelectedModelRef(modelRefs?.current[indexOfModel]);
      setBoundingBox(bbox);
      boundingBoxGlobal = bbox;

      let modelWidth = parseFloat((bbox?.max?.x - bbox?.min?.x).toFixed(4));

      if (
        elements[indexOfModel].model.startsWith("Model_15") ||
        elements[indexOfModel].model.startsWith("Model_16") ||
        elements[indexOfModel].model.startsWith("Model_17") ||
        elements[indexOfModel].model.startsWith("Model_18")
      ) {
        setShowAxesModeX(false);
        setShowAxesModeY(false);
        setShowAxesModeYdouble(false);
        setShowAxesModeYleft(false);
        setShowAxesModeYright(false);
      } else {
        /* Calculations */
        /* X-Axes Test */

        rayStartX.y = boundingBoxGlobal.min.y + 0.1;
        rayStartX.x = boundingBoxGlobal.max.x + 0.1;
        rayStartX.z = -0.3;

        raycasterX.ray.origin.copy(rayStartX);
        raycasterX.ray.direction.set(1, 0, 0);

        const intersectionOnX = raycasterX.intersectObjects(
          modelRefs.current,
          true
        );
        let intersectsOnX = intersectionOnX.length > 0;
        setHelperX([toTheRight, rayStartX, 3]);

        /* Y-Axes possible Test */

        if (bbox?.min?.x > -1.1) {
          rayStartYpossible.y = boundingBoxGlobal.max.y + 0.1;
          rayStartYpossible.x = boundingBoxGlobal.min.x;
          rayStartYpossible.z = -0.3;

          raycasterYpossible.ray.origin.copy(rayStartYpossible);
          raycasterYpossible.ray.direction.set(-1, 0, 0);

          let intersectionsOnYpossible = raycasterYpossible.intersectObjects(
            modelRefs.current,
            true
          );
          intersectsOnYpossible = intersectionsOnYpossible.length > 0;
          setHelperYpossible([toTheLeft, rayStartYpossible, 3]);
        } else {
          intersectsOnYpossible = true;
        }

        /* Y-Axes left Test */

        rayStartYleft.y = boundingBoxGlobal.max.y + 0.05;
        rayStartYleft.x = boundingBoxGlobal.min.x + 0.05;
        rayStartYleft.z = -0.3;

        raycasterYleft.ray.origin.copy(rayStartYleft);
        raycasterYleft.ray.direction.set(0, 1, 0);

        let intersectionsOnYleft = raycasterYleft.intersectObjects(
          modelRefs.current,
          true
        );
        intersectsOnYleft = intersectionsOnYleft.length > 0;
        setHelperYleft([upwards, rayStartYleft, 3]);

        /* Y-Axes right Test */

        rayStartYright.y = boundingBoxGlobal.max.y + 0.05;
        rayStartYright.x = boundingBoxGlobal.max.x - 0.05;
        rayStartYright.z = -0.3;

        raycasterYright.ray.origin.copy(rayStartYright);
        raycasterYright.ray.direction.set(0, 1, 0);

        let intersectionsOnYright = raycasterYright.intersectObjects(
          modelRefs.current,
          true
        );
        intersectsOnYright = intersectionsOnYright.length > 0;
        setHelperYright([upwards, rayStartYright, 3]);

        /* Y-Axes right double Test */

        rayStartYrightDouble.y = boundingBoxGlobal.max.y + 0.05;
        rayStartYrightDouble.x =
          boundingBoxGlobal.max.x + modelWidth / 2 - 0.05;
        rayStartYrightDouble.z = -0.3;

        raycasterYrightDouble.ray.origin.copy(rayStartYrightDouble);
        raycasterYrightDouble.ray.direction.set(0, -1, 0);

        let intersectionsOnYrightDouble =
          raycasterYrightDouble.intersectObjects(modelRefs.current, true);
        intersectsOnYrightDouble = intersectionsOnYrightDouble.length > 0;
        setHelperYrightDouble([downwards, rayStartYrightDouble, 3]);
        setSpaceUnderModel(intersectionsOnYrightDouble[0]?.distance);

        /* Y-Axes double Test */

        rayStartYdouble.y = boundingBoxGlobal.max.y + 0.05;
        rayStartYdouble.x = boundingBoxGlobal.min.x + modelWidth * 2 - 0.05;
        rayStartYdouble.z = -0.3;

        raycasterYdouble.ray.origin.copy(rayStartYdouble);
        raycasterYdouble.ray.direction.set(0, -1, 0);

        rayStartXTop.y = boundingBoxGlobal.max.y + 0.1;
        rayStartXTop.x = boundingBoxGlobal.min.x;
        rayStartXTop.z = -0.3;

        raycasterXTop.ray.origin.copy(rayStartXTop);
        raycasterXTop.ray.direction.set(1, 0, 0);

        let intersectionsOnXTop = raycasterXTop.intersectObjects(
          modelRefs.current,
          true
        );
        setHelperXTop([toTheRight, rayStartXTop, 3]);

        let intersectionsOnYdouble = raycasterYdouble.intersectObjects(
          modelRefs.current,
          true
        );
        setHelperYdouble([downwards, rayStartYdouble, 3]);

        if (
          intersectionsOnYdouble[0]?.distance < 0.09 &&
          !intersectsOnYleft &&
          !intersectsOnYright &&
          modelWidth !== 0.4 &&
          modelWidth !== 1.2
        ) {
          if (intersectionsOnXTop[0]) {
            intersectionsOnXTop[0]?.distance > modelWidth * 1.99 ||
            (!intersectionsOnXTop && doublePossible)
              ? (doublePossible = true)
              : (doublePossible = false);
          } else {
            !intersectionsOnXTop
              ? (doublePossible = true)
              : (doublePossible = true);
          }
        } else {
          doublePossible = false;
        }

        /* Setting the states based on calculations */

        /* Controlling x values */
        if (
          intersectsOnX ||
          intersectionOnX[0]?.distance < 0.31 ||
          bbox?.min?.y > -0.9
        ) {
          setShowAxesModeX(false);
        } else {
          setShowAxesModeX(true);
        }

        /* Controlling doubleY values */

        doublePossible
          ? setShowAxesModeYdouble(true)
          : setShowAxesModeYdouble(false);

        /* Controlling y values */

        if (!intersectsOnYright && !intersectsOnYleft) {
          setShowAxesModeY(true);
        } else {
          setShowAxesModeY(false);
        }

        /* Controlling yLeft values */

        if (!intersectsOnYleft && modelWidth > 0.5) {
          setShowAxesModeYleft(true);
        } else {
          setShowAxesModeYleft(false);
        }

        /* Controlling yRight values */
        if (!intersectsOnYright && modelWidth > 0.5) {
          setShowAxesModeYright(true);
        } else {
          setShowAxesModeYright(false);
        }

        setSelectedModelWidth(
          parseFloat((bbox?.max?.x - bbox?.min?.x).toFixed(4))
        );
      }
    } else {
      setShowAxesModeX(false);
      setShowAxesModeY(false);
      setShowAxesModeYdouble(false);
      setShowAxesModeYleft(false);
      setShowAxesModeYright(false);
      setBoundingBox({
        max: [0, 0, 0],
        min: [0, 0, 0],
      });
    }
  }, [indexOfModel, modelRefs]);

  const resetAxesModes = () => {
    setEditMode(false);
    setShowAxesModeX(false);
    setShowAxesModeY(false);
    setShowAxesModeYdouble(false);
    setShowAxesModeYleft(false);
    setShowAxesModeYright(false);
    setIndexOfModel(null);
    setBoundingBox({
      max: [0, 0, 0],
      min: [0, 0, 0],
    });
    setElementToAdd("");
    if (elements.length > 2) {
      setAxes(null);
    } else {
      setAxes("start");
    }
  };

  const handleDeleteElement = () => {
    rayStartXGriffleisten.y = boundingBox.min.y + 0.3;
    rayStartXGriffleisten.x = (boundingBox.max.x + boundingBox.min.x) / 2;
    rayStartXGriffleisten.z = -0.245;

    raycasterXGriffleisten.ray.origin.copy(rayStartXGriffleisten);
    raycasterXGriffleisten.ray.direction.set(0, -1, 0);

    const griffleisteToDelete = raycasterXGriffleisten.intersectObjects(
      modelRefs.current,
      true
    );

    let firstIntersectedGriffleiste = null;
    let modelToRemoveUnderSelection;

    raycasterXGriffleisten
      .intersectObjects(modelRefs.current)
      .some((intersect) => {
        if (intersect?.object?.name === "griffleiste") {
          if (boundingBox?.min?.y < -0.8) {
            setCompleteWidth(
              completeWidth - sizesMap[elements?.[indexOfModel]?.model]?.width
            );
            setHeightControls((prevHeights) => [...prevHeights.slice(0, -1)]);
          }
          modelToRemoveUnderSelection = "Griffleiste";
          firstIntersectedGriffleiste = intersect.object;
          return true;
        } else if (intersect?.object?.name === "sockel") {
          if (boundingBox?.min?.y < -0.8) {
            setCompleteWidth(
              completeWidth - sizesMap[elements?.[indexOfModel]?.model]?.width
            );
            setHeightControls((prevHeights) => [...prevHeights.slice(0, -1)]);
          }
          modelToRemoveUnderSelection = "Sockel";
          firstIntersectedGriffleiste = intersect.object;
          return true;
        }
        return false;
      });

    let firstIntersectedGriffleisteIndex;

    if (firstIntersectedGriffleiste) {
      firstIntersectedGriffleisteIndex = firstIntersectedGriffleiste.index;
      if (
        firstIntersectedGriffleiste.width ==
        sizesMap?.[elements?.[indexOfModel]?.model]?.width
      ) {
        if (indexOfModel !== null) {
          let indices = [];
          let modelsCopy = modelRefs.current;
          indices.push(indexOfModel, firstIntersectedGriffleisteIndex);

          if (indices) {
            const filteredElements = elements.filter((_, index) => {
              return !indices.includes(index);
            });

            const filteredElementsMaterials = elementsMaterials.filter(
              (_, index) => {
                return !indices.includes(index);
              }
            );

            setElementToDelete(indices);

            if (indices.length) {
              // Iterate in reverse order to avoid reordering indices
              for (let i = indices.length - 1; i >= 0; i--) {
                const index = indices[i];
                const modelToRemove = modelRefs.current[index];
                if (modelToRemove) {
                  modelToRemove.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                      child.geometry.dispose();
                      if (child.material.map) {
                        child.material.map.dispose();
                      }
                      child.material.dispose();
                    }
                  });
                }
              }
            }

            if (filteredElements && filteredElementsMaterials) {
              setElements(filteredElements);
              setElementsMaterials(filteredElementsMaterials);
            }

            setElementsRendering(elements);

            // Reset the selected model index
            setIndexOfModel(null);
          }
        }
      } else {
        let lengthOfGriffleisten =
          firstIntersectedGriffleiste.width -
          sizesMap?.[elements?.[indexOfModel]?.model]?.width;

        const griffleistenBox = new THREE.Box3();
        griffleistenBox.setFromObject(firstIntersectedGriffleiste);
        let widthOfLeftGriffleiste = parseFloat(
          boundingBox?.min?.x - griffleistenBox?.min?.x
        ).toFixed(1);

        let widthOfRightGriffleiste = (
          lengthOfGriffleisten - widthOfLeftGriffleiste
        ).toFixed(2);

        const availableLengths = [
          0.3, 0.4, 0.6, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2.1, 2.2, 2.4, 2.7,
        ];

        function findCombinations(targetWidth, lengths) {
          // Base case: if targetWidth is 0, we found a valid combination
          if (targetWidth === 0) {
            return [];
          }

          // Initialize the best combination as an empty array
          let bestCombination = [];

          for (let i = 0; i < lengths.length; i++) {
            const currentLength = lengths[i];
            // If the current length is greater than the remaining targetWidth, skip it
            if (currentLength > targetWidth) {
              continue;
            }

            // Recursively find combinations for the remaining width
            const remainingWidth = targetWidth - currentLength;
            const remainingCombinations = findCombinations(
              remainingWidth,
              lengths.slice(i)
            );

            // If a valid combination was found, append the current length to it
            if (remainingCombinations !== null) {
              const currentCombination = [
                currentLength,
                ...remainingCombinations,
              ];

              // If it's better than the previous best combination, update the bestCombination
              if (
                bestCombination.length === 0 ||
                currentCombination.length < bestCombination.length
              ) {
                bestCombination = currentCombination;
              }
            }
          }

          // If no valid combination was found, return null
          return bestCombination.length > 0 ? bestCombination : null;
        }

        let optionsForLeftSide;
        let optionsForRightSide;

        if (widthOfLeftGriffleiste) {
          optionsForLeftSide = findCombinations(
            widthOfLeftGriffleiste,
            availableLengths
          );
        }

        if (widthOfRightGriffleiste) {
          optionsForRightSide = findCombinations(
            widthOfRightGriffleiste,
            availableLengths
          );
        }

        if (indexOfModel !== null) {
          let indices = [];
          let modelsCopy = modelRefs.current;
          indices.push(indexOfModel, firstIntersectedGriffleisteIndex);

          if (indices) {
            const filteredElements = elements.filter((_, index) => {
              return !indices.includes(index);
            });

            const filteredElementsMaterials = elementsMaterials.filter(
              (_, index) => {
                return !indices.includes(index);
              }
            );

            setElementToDelete(indices);

            if (indices.length) {
              // Iterate in reverse order to avoid reordering indices
              for (let i = indices.length - 1; i >= 0; i--) {
                const index = indices[i];
                const modelToRemove = modelRefs.current[index];
                if (modelToRemove) {
                  modelToRemove.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                      child.geometry.dispose();
                      if (child.material.map) {
                        child.material.map.dispose();
                      }
                      child.material.dispose();
                    }
                  });
                }
              }
            }

            let correctLeftGriffleiste;
            let correctRightGriffleiste;

            if (modelToRemoveUnderSelection === "Griffleiste") {
              if (optionsForLeftSide) {
                correctLeftGriffleiste = Object.keys(sizesMap).find(
                  (modelName) => {
                    return (
                      modelName.startsWith("Model_16") &&
                      sizesMap[modelName].width == optionsForLeftSide
                    );
                  }
                );
                filteredElements.push({
                  x: boundingBox?.min?.x - optionsForLeftSide / 2,
                  y: boundingBox?.min?.y - 0.04 + 0.015,
                  z: -0.5,
                  model: correctLeftGriffleiste,
                  griffleiste: true,
                  sockel: false,
                });
                filteredElementsMaterials.push({
                  front: null,
                  korpus:
                    "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
                  griffleiste: true,
                  sockel: false,
                });
              }

              if (optionsForRightSide) {
                correctRightGriffleiste = Object.keys(sizesMap).find(
                  (modelName) => {
                    return (
                      modelName.startsWith("Model_16") &&
                      sizesMap[modelName].width == optionsForRightSide
                    );
                  }
                );
                filteredElements.push({
                  x: boundingBox?.max?.x + optionsForRightSide / 2,
                  y: boundingBox?.min?.y - 0.04 + 0.015,
                  z: -0.5,
                  model: correctRightGriffleiste,
                  griffleiste: true,
                  sockel: false,
                });
                filteredElementsMaterials.push({
                  front: null,
                  korpus:
                    "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
                  griffleiste: true,
                  sockel: false,
                });
              }

              if (filteredElements && filteredElementsMaterials) {
                setElements(filteredElements);
                setElementsMaterials(filteredElementsMaterials);
              }

              setElementsRendering(elements);
            } else if (modelToRemoveUnderSelection === "Sockel") {
              if (optionsForLeftSide) {
                correctLeftGriffleiste = Object.keys(sizesMap).find(
                  (modelName) => {
                    return (
                      modelName.startsWith("Model_15") &&
                      sizesMap[modelName].width == optionsForLeftSide
                    );
                  }
                );
                filteredElements.push({
                  x: boundingBox?.min?.x - optionsForLeftSide / 2,
                  y: boundingBox?.min?.y - 0.04 + 0.015,
                  z: -0.5,
                  model: correctLeftGriffleiste,
                  griffleiste: false,
                  sockel: true,
                });
                filteredElementsMaterials.push({
                  front: null,
                  korpus:
                    "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
                  griffleiste: false,
                  sockel: true,
                });
              }

              if (optionsForRightSide) {
                correctRightGriffleiste = Object.keys(sizesMap).find(
                  (modelName) => {
                    return (
                      modelName.startsWith("Model_15") &&
                      sizesMap[modelName].width == optionsForRightSide
                    );
                  }
                );
                filteredElements.push({
                  x: boundingBox?.max?.x + optionsForRightSide / 2,
                  y: boundingBox?.min?.y - 0.04 + 0.015,
                  z: -0.5,
                  model: correctRightGriffleiste,
                  griffleiste: false,
                  sockel: true,
                });
                filteredElementsMaterials.push({
                  front: null,
                  korpus:
                    "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg",
                  griffleiste: false,
                  sockel: true,
                });
              }

              if (filteredElements && filteredElementsMaterials) {
                setElements(filteredElements);
                setElementsMaterials(filteredElementsMaterials);
              }

              setElementsRendering(elements);
            }

            // Reset the selected model index
            // setIndexOfModel(null);
          }
        }
      }
    }
    if (elements.length > 2) {
      setTotalHeight(0);
    }
    if (elements.length < 5) {
      setAxes("start");
    } else {
      setAxes(null);
    }
    setEditMode(false);
  };

  useEffect(() => {
    modelRefs.current = modelsArray;
  }, [modelsArray]);

  const updateModelRefs = (newModelRefs) => {
    modelRefs.current = newModelRefs;
    setModelsArray(modelRefs.current);
  };

  const backgroundFloor = useLoader(
    THREE.TextureLoader,
    process.env.PUBLIC_URL + "/images/hintergrundFloorNoBands.jpg"
  );
  backgroundFloor.rotation = Math.PI;
  backgroundFloor.wrapS = RepeatWrapping;
  backgroundFloor.wrapT = RepeatWrapping;
  backgroundFloor.needsUpdate = true;

  const backgroundWall = useLoader(
    THREE.TextureLoader,
    process.env.PUBLIC_URL + "/images/hintergrundWallNoBands.jpg"
  );
  backgroundWall.wrapS = RepeatWrapping;
  backgroundWall.wrapT = RepeatWrapping;
  backgroundWall.needsUpdate = true;

  const openSidebar = () => {
    setShowSidebar(true);
    sidebarRef?.current?.classList.add(sidebarStyles.visibleSidebar);
    const positions = { x: 200, y: 200 };
    setLabelPosition(positions);
  };

  const handleResize = () => {
    const newViewportWidth = window.innerWidth;
    setViewportWidth(newViewportWidth);
  };

  useEffect(() => {
    if (alreadyBought === true) {
      setShowSidebar(false);
    }
  }, [alreadyBought]);

  // useEffect for handling resizes and viewport changes

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("DOMContentLaoded", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("DOMContentLaoded", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  // useEffect for swiping up

  useEffect(() => {
    let startY = 0;

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      const deltaY = e.changedTouches[0].clientY - startY;
      if (deltaY < -250 && alreadyBought === false) {
        setShowSidebar(true);
      }
      if (deltaY > 250) {
        setShowSidebar(false);
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  return (
    <>
      {/* <HeightsControlComponent models={modelRefs} /> */}
      {showMaterialOverview && (
        <div className={styles.materialsOverviewWrapper}>
          <div className={styles.materialsOverview}>
            <MaterialsOverview />
          </div>
        </div>
      )}

      <div className={styles.app} ref={statsRef}>
        {/* <div className={styles.header}>
          <span>Shop</span> <span>Möbelkonfigurator</span>{" "}
          <span>4KantDesign</span> <span>Kontakt</span> <span>Schreinerei</span>
        </div> */}
        <div
          className={`${styles.modelwrapper} ${
            showSidebar && viewportWidth < 1400 ? styles.smallerViewport : null
          }`}
          ref={modelwrapperRef}
        >
          {loading ? (
            <div
              className={`${styles.loadingContainer} ${
                showSidebar ? styles.smallerViewport : null
              } ${alreadyBought ? styles.fullWidth : null}`}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/HAL_9999_Loading-Animation_3.gif"
                }
              />
            </div>
          ) : null}

          <StockOverview />

          {alreadyBought === false && (
            <button
              className={btnStyles.wizardBackButton}
              onClick={() => {
                setShowBackToWizardModal(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-chevron-right-solid-white.svg"
                }
              />
            </button>
          )}

          <div
            className={`${styles.modalBackground} ${
              showBacktoWizardModal ? "" : styles.hidePopupModal
            }`}
          >
            <div className={`${styles.popupModal}`}>
              <p>
                {" "}
                Möchtest du zu den Voreinstellungen zurück und die Konfiguration
                verwerfen?
              </p>
              <button
                className={btnStyles.whiteModalButton}
                onClick={() => {
                  setShowBackToWizardModal(false);
                }}
              >
                Nein, bei Konfiguration bleiben
              </button>

              <button
                className={btnStyles.blackModalButton}
                onClick={() => {
                  setElements([]);
                  setElementsMaterials([]);
                  setElementsRendering([]);
                  setCompleteWidth(0);
                  setTotalHeight(0);
                  setElementToAdd("");
                  setHeightControls([]);
                  setWizardReady(false);
                }}
              >
                Ja, zurück zu den Voreinstellungen
              </button>
            </div>
          </div>

          {alreadyBought === false && (
            <button
              className={btnStyles.refreshSceneButton}
              onClick={() => {
                setShowRefreshScene(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/arrows-rotate-solid.svg"
                }
              />
            </button>
          )}

          <div
            className={`${styles.modalBackground} ${
              showRefreshScene ? "" : styles.hidePopupModal
            }`}
          >
            <div className={`${styles.popupModal}`}>
              <p> Möchtest du die Konfiguration wirklich löschen?</p>
              <button
                className={btnStyles.whiteModalButton}
                onClick={() => {
                  setShowRefreshScene(false);
                }}
              >
                Nein, bei Konfiguration bleiben
              </button>
              <button
                className={btnStyles.blackModalButton}
                onClick={() => {
                  setElements([]);
                  setElementsMaterials([]);
                  setElementsRendering([]);
                  setCompleteWidth(0);
                  setTotalHeight(0);
                  setElementToAdd("");
                  setAxes("start");
                  setHeightControls([]);
                  setShowRefreshScene(false);
                }}
              >
                Ja, ich möchte von vorn beginnen
              </button>
            </div>
          </div>

          {showSidebar === false &&
          alreadyBought === false &&
          viewportWidth < 1400 ? (
            <div
              className={`${btnStyles.sidebarButton} ${styles.pointer}`}
              onClick={() => {
                openSidebar();
              }}
            >
              <p>Optionen</p>
              <img
                src={process.env.PUBLIC_URL + "/images/icons/arrow_left.svg"}
                alt="Aufklappen"
              />
            </div>
          ) : null}

          <div
            className={styles.controls}
            onClick={() => {
              setShowControls(true);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/icons/steuerung.svg"}
              alt="Steuerung"
            />
          </div>

          <div
            className={`${styles.controlsModal} ${
              showControls ? styles.showModal : styles.hideModal
            }`}
          >
            <div
              onClick={() => {
                setShowControls(false);
              }}
              className={styles.backContainer}
            >
              <button className={btnStyles.closebutton}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/icons/circle-chevron-right-solid.svg"
                  }
                />
              </button>
              <span>zurück</span>
            </div>
            <div className={styles.modalContent}>
              <h2>Konfigurator Steuerung</h2>
              <div className={styles.controlsHeadline}>
                <img src={process.env.PUBLIC_URL + "/images/icons/mouse.svg"} />
                <p className={styles.boldFont}>Maus-Steuerung:</p>
              </div>
              <ul>
                <li>Linker Mausklick: Elemente / Optionen auswählen</li>
                <li>Linke Maustaste gedrückt halten: Ansicht rotieren</li>
                <li>
                  Linke Maustaste gedrückt halten + Shift: Ansicht bewegen
                </li>
                <li>Mausrad: Zoomen</li>
                <li>Rechte Maustaste gedrückt halten: Ansicht bewegen</li>
              </ul>
              <div className={styles.controlsHeadline}>
                <img src={process.env.PUBLIC_URL + "/images/icons/swipe.svg"} />
                <p className={styles.boldFont}>Touch-Steuerung:</p>
              </div>
              <ul>
                <li>Mit einem Finger tippen: Elemente / Optionen auswählen</li>
                <li>Mit einem Finger gedrückt halten: Ansicht rotieren</li>
                <li>Mit zwei Fingern gedrückt halten: Ansicht bewegen</li>
                <li>Finger auseinander / zusammen führen: Zoomen</li>
              </ul>
            </div>
          </div>

          {showFronten ? (
            <>
              <div
                onClick={() => {
                  setShowFronten(false);
                }}
                className={`${btnStyles.frontenButton} ${styles.pointer}`}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/visibility_off.svg"
                  }
                  alt="Fronten ausblenden"
                />
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  setShowFronten(true);
                }}
                className={`${btnStyles.frontenButton} ${styles.pointer}`}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/visibility.svg"}
                  alt="Fronten einblenden"
                />
              </div>
            </>
          )}

          <Suspense fallback={<LoadingAnimation />}>
            <Canvas
              className={`${styles.model} ${
                alreadyBought ? styles.fullWidth : null
              }`}
              onPointerMissed={resetAxesModes}
              flat
            >
              {/* <LoadingObserver /> */}
              <Suspense>
                {/* <Perf /> */}
                {/* <RaycasterHelper /> */}
                <ModelOutline />
                <ModelPreview />
                <SceneProperties
                  element={elementToDelete}
                  models={modelRefs}
                  orbitRef={orbitRef}
                />

                <directionalLight
                  position={[0, 10, 10]}
                  color="white"
                  intensity={1}
                />
                <ambientLight intensity={0.2} />

                <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1, 5]}>
                  <planeGeometry args={[70, 14]} />
                  <meshBasicMaterial map={backgroundFloor} />
                </mesh>

                <mesh rotation={[0, 0, 0]} position={[0, 8.5, -1]}>
                  <planeGeometry args={[70, 20]} />
                  <meshBasicMaterial map={backgroundWall} />
                </mesh>

                <ElementsDisplay
                  modelRefs={modelRefs}
                  updateModelRefs={updateModelRefs}
                />

                <Seitenwangen modelRefs={modelRefs} />

                <OrbitControls
                  enablePan={true}
                  ref={orbitRef}
                  enableZoom={true}
                  enableRotate={true}
                  minDistance={0.5}
                  maxDistance={5}
                  minAzimuthAngle={-Math.PI / 12}
                  maxAzimuthAngle={Math.PI / 12}
                  minPolarAngle={Math.PI / 3}
                  maxPolarAngle={Math.PI - Math.PI / 2}
                />
              </Suspense>
            </Canvas>
          </Suspense>

          {indexOfModel !== undefined &&
            indexOfModel !== null &&
            alreadyBought === false &&
            !elements?.[indexOfModel]?.model.startsWith("Model_15") &&
            !elements?.[indexOfModel]?.model.startsWith("Model_16") &&
            !elements?.[indexOfModel]?.model.startsWith("Model_17") &&
            !elements?.[indexOfModel]?.model.startsWith("Model_18") && (
              <div
                className={styles.labelContainer}
                style={{
                  left: `${labelPosition.x}px`,
                  top: `${
                    viewportWidth > 768
                      ? labelPosition.y
                      : labelPosition.y - 100
                  }px`,
                }}
              >
                {showAxesModeX ? (
                  <div
                    className={`${styles.labelHtmlX} ${styles.pointer} ${
                      axes === "x" && styles.activeLabel
                    }`}
                    onClick={() =>
                      startTransition(() => {
                        setEditMode(true);
                        setAxes("x");
                        if (viewportWidth < 1400 && alreadyBought === false) {
                          setShowSidebar(true);
                        }
                      })
                    }
                  >
                    {axes === "x" ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active_selected.svg"
                        }
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active.svg"
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.labelHtmlX}
     ${axes === "x" && styles.activeLabel}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/axes_inactive.svg"
                      }
                    />
                  </div>
                )}

                {showAxesModeY ? (
                  <div
                    className={`${styles.labelHtmlY} ${styles.pointer} ${
                      axes === "y" && styles.activeLabel
                    }`}
                    onClick={() =>
                      startTransition(() => {
                        setEditMode(true);
                        setAxes("y");
                        if (viewportWidth < 1400 && alreadyBought === false) {
                          setShowSidebar(true);
                        }
                      })
                    }
                  >
                    {axes === "y" ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active_selected.svg"
                        }
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active.svg"
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.labelHtmlY} ${
                      styles.transparentAxes
                    } ${axes === "y" && styles.activeLabel}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/axes_inactive.svg"
                      }
                    />
                  </div>
                )}

                {showAxesModeYleft ? (
                  <div
                    className={`${styles.labelHtmlYleft} ${styles.pointer} ${
                      axes === "yLeft" && styles.activeLabel
                    }`}
                    onClick={() =>
                      startTransition(() => {
                        setEditMode(true);
                        setAxes("yLeft");
                        if (viewportWidth < 1400 && alreadyBought === false) {
                          setShowSidebar(true);
                        }
                      })
                    }
                  >
                    {axes === "yLeft" ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active_selected.svg"
                        }
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active.svg"
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.labelHtmlYleft} ${
                      axes === "yLeft" && styles.activeLabel
                    }`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/axes_inactive.svg"
                      }
                    />
                  </div>
                )}

                {showAxesModeYright ? (
                  <div
                    className={`${styles.labelHtmlYright} ${styles.pointer} ${
                      axes === "yRight" && styles.activeLabel
                    }`}
                    onClick={() =>
                      startTransition(() => {
                        setEditMode(true);
                        setAxes("yRight");
                        if (viewportWidth < 1400 && alreadyBought === false) {
                          setShowSidebar(true);
                        }
                      })
                    }
                  >
                    {axes === "yRight" ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active_selected.svg"
                        }
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active.svg"
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.labelHtmlYright} ${
                      axes === "yRight" && styles.activeLabel
                    }`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/axes_inactive.svg"
                      }
                    />
                  </div>
                )}

                {showAxesModeYdouble ? (
                  <div
                    className={`${styles.labelHtmlYdouble} ${styles.pointer} ${
                      axes === "yDouble" && styles.activeLabel
                    }`}
                    onClick={() =>
                      startTransition(() => {
                        setEditMode(true);
                        setAxes("yDouble");
                        if (viewportWidth < 1400 && alreadyBought === false) {
                          setShowSidebar(true);
                        }
                      })
                    }
                  >
                    {axes === "yDouble" ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_active_selected.svg"
                        }
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/axes_double_active.svg"
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.labelHtmlYdouble} ${
                      axes === "yDouble" && styles.activeLabel
                    }`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/axes_double_inactive.svg"
                      }
                    />
                  </div>
                )}

                {(indexOfModel !== null &&
                  showAxesModeY === true &&
                  boundingBox?.min.y > -0.8) ||
                (indexOfModel !== null &&
                  showAxesModeY === true &&
                  showAxesModeX === true) ? (
                  <button
                    className={`${styles.deleteButton} ${styles.pointer}`}
                    onClick={() => handleDeleteElement()}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/delete_model.svg"
                      }
                    />
                  </button>
                ) : (
                  <button
                    className={`${styles.deleteButton} ${styles.transparentAxes}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/delete_model.svg"
                      }
                    />
                  </button>
                )}
              </div>
            )}

          {!editMode && !elements.length && (
            <div className={`${styles.labelHtml} ${styles.labelHtmlStart}`}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-info-sharp-light.svg"
                }
              />
              <p>Platziere dein erstes Element.</p>
            </div>
          )}

          <SizesInformation models={modelRefs} />
          <div
            className={`${
              showSidebar || viewportWidth > 1400
                ? sidebarStyles.visibleSidebar
                : sidebarStyles.hiddenSidebar
            }`}
            ref={sidebarRef}
          >
            {!alreadyBought && (
              <Sidebar models={modelRefs} updateModelRefs={updateModelRefs} />
            )}
            <Inventory />
            <Prices />
          </div>
        </div>
      </div>
    </>
  );
}

export default Configurator;

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../../contextProviders/ContextProvider";
import { RepeatWrapping } from "three";

export function Model_110321_600x1548_kl_led(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/11/03/110321_600x1548_kl_led.glb"
  );

  const { index } = props;
  const { elementsMaterials, showFronten } = useGlobalContext();

  const frontTexture = useTexture(elementsMaterials[index]?.front);
  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  frontTexture.colorSpace = THREE.SRGBColorSpace;
  frontTexture.needsUpdate = true;

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  frontTexture.wrapS = RepeatWrapping;
  frontTexture.wrapT = RepeatWrapping;

  const blackMaterial = new THREE.MeshStandardMaterial({ color: 0x000000 });

  const ledMaterial = new THREE.MeshStandardMaterial({
    emissive: new THREE.Color(0xffe2c5), // Set to your desired color
    emissiveIntensity: 2, // Adjust as needed
  });

  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        <mesh geometry={nodes.brep_7.geometry} material={ledMaterial} />
        <mesh geometry={nodes.brep_8.geometry} material={ledMaterial} />
      </group>
      <group scale={0.001}>
        <mesh
          geometry={nodes.brep_12.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_14.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_16.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_20.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_22.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_26.geometry}
          material={materials.Scharniere}
        />
      </group>
      <mesh
        geometry={nodes.brep_38.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        scale={0.001}
      />
      {showFronten ? (
        <mesh
          geometry={nodes.brep_9.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
        />
      ) : (
        <mesh
          geometry={nodes.brep_9.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
          visible={false}
        />
      )}

      <mesh
        geometry={nodes.brep_42.geometry}
        material={blackMaterial}
        scale={0.001}
      />
    </group>
  );
}

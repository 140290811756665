import React, { useEffect, useState, useRef } from "react";
import SidebarMaterials from "./SidebarMaterials";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import SidebarElements from "./SidebarElements";
import { Canvas } from "@react-three/fiber";
import { Raycaster, Vector3 } from "three";

import styles from "../../styles/sidebar.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import stylesConfigurator from "../../styles/configurator.module.scss";
import sizesMap from "./sizesMap";
import * as THREE from "three";
import Dropdown from "react-bootstrap/Dropdown";
import Cabinets from "./Cabinets";
import CurrentElement from "./CurrentElement";
import Shelves from "./Shelves";
import Drawers from "./Drawers";
import MaterialsOverview from "./MaterialsOverview";

const Sidebar = ({ models, updateModelRefs }) => {
  const {
    axes,
    isHeightEqual,
    elements,
    setElements,
    indexOfModel,
    showSidebar,
    setShowSidebar,
    viewportWidth,
    sidebarMainOption,
    setSidebarMainOption,
    showCabinets,
    setShowCabinets,
    showShelves,
    setShowShelves,
    showDrawers,
    setShowDrawers,
    price,
    setShowStockOverview,
  } = useGlobalContext();
  const [moduleType, setModuleType] = useState("");
  const [hasLED, setHasLed] = useState(false);
  const [canShowRightScharniere, setCanShowRightScharniere] = useState(false);
  const [canShowLeftScharniere, setCanShowLeftScharniere] = useState(false);
  const [canShowModifications, setCanShowModifications] = useState(false);

  // const raycasterXGriffleisten = new Raycaster();
  // raycasterXGriffleisten.far = 20;
  // const rayStartXGriffleisten = new Vector3(0, 0, 0);

  const raycasterXLeft = new Raycaster();
  raycasterXLeft.far = 20;
  const rayStartXLeft = new Vector3(0, 0, 0);

  const raycasterXRight = new Raycaster();
  raycasterXRight.far = 20;
  const rayStartXRight = new Vector3(0, 0, 0);

  useEffect(() => {
    let currentModel = elements?.[indexOfModel]?.model;

    if (
      currentModel?.startsWith("Model_15") ||
      currentModel?.startsWith("Model_16") ||
      currentModel?.startsWith("Model_17") ||
      currentModel?.startsWith("Model_18")
    ) {
      setHasLed(false);
    } else {
      setHasLed(true);
      if (
        currentModel?.startsWith("Model_11") ||
        currentModel?.startsWith("Model_12")
      ) {
        setCanShowRightScharniere(true);
        setCanShowLeftScharniere(true);
      }
      if (
        currentModel?.startsWith("Model_1103") ||
        currentModel?.startsWith("Model_1203")
      ) {
        setCanShowModifications(true);
      } else {
        setCanShowModifications(false);
      }
      if (
        currentModel?.startsWith("Model_13") ||
        currentModel?.startsWith("Model_14")
      ) {
        setCanShowRightScharniere(false);
        setCanShowLeftScharniere(false);
      }
    }
  }, [indexOfModel]);

  const handleLedChange = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    if (currentModel.endsWith("_led")) {
      let normalModel =
        currentModel.substring(0, 11) +
        "0" +
        currentModel.substring(12, currentModel.length - 4);

      let elementsCopy = [...elements];
      if (elementsCopy[indexOfModel]) {
        elementsCopy[indexOfModel].model = normalModel;
        setElements(elementsCopy);
      }
    } else {
      let ledModel =
        currentModel.substring(0, 11) +
        "1" +
        currentModel.substring(12) +
        "_led";

      let elementsCopy = [...elements];
      if (elementsCopy[indexOfModel]) {
        elementsCopy[indexOfModel].model = ledModel;
        setElements(elementsCopy);
      }
    }
  };

  const handleScharniereRechts = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    let modelWithScharniereRechts =
      currentModel?.substring(0, 7) + "2" + currentModel?.substring(8);

    let elementsCopy = [...elements];
    if (elementsCopy[indexOfModel]) {
      elementsCopy[indexOfModel].model = modelWithScharniereRechts;
      setElements(elementsCopy);
    }
  };

  const handleScharniereLinks = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    let modelWithScharniereLinks =
      currentModel?.substring(0, 7) + "1" + currentModel?.substring(8);

    let elementsCopy = [...elements];
    if (elementsCopy[indexOfModel]) {
      elementsCopy[indexOfModel].model = modelWithScharniereLinks;
      setElements(elementsCopy);
    }
  };

  const handleFachboeden = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    let newModelName;

    if (currentModel.endsWith("_led")) {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110311_600x1548_fb_led";
      } else {
        newModelName = "Model_120311_600x1548_fb_led";
      }
    } else {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110310_600x1548_fb";
      } else {
        newModelName = "Model_120310_600x1548_fb";
      }
    }
    let elementsCopy = [...elements];
    if (elementsCopy[indexOfModel]) {
      elementsCopy[indexOfModel].model = newModelName;
      setElements(elementsCopy);
    }
  };

  const handleKleiderstange = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    let newModelName;

    if (currentModel.endsWith("_led")) {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110321_600x1548_kl_led";
      } else {
        newModelName = "Model_120321_600x1548_kl_led";
      }
    } else {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110320_600x1548_kl";
      } else {
        newModelName = "Model_120320_600x1548_kl";
      }
    }
    let elementsCopy = [...elements];
    if (elementsCopy[indexOfModel]) {
      elementsCopy[indexOfModel].model = newModelName;
      setElements(elementsCopy);
    }
  };

  const handleKleiderlift = () => {
    let currentModel = elements?.[indexOfModel]?.model;
    let newModelName;

    if (currentModel.endsWith("_led")) {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110331_600x1548_klf_led";
      } else {
        newModelName = "Model_120331_600x1548_klf_led";
      }
    } else {
      if (currentModel.startsWith("Model_11")) {
        newModelName = "Model_110330_600x1548_klf";
      } else {
        newModelName = "Model_120330_600x1548_klf";
      }
    }
    let elementsCopy = [...elements];
    if (elementsCopy[indexOfModel]) {
      elementsCopy[indexOfModel].model = newModelName;
      setElements(elementsCopy);
    }
  };

  useEffect(() => {
    const modelName = elements?.[indexOfModel]?.model;
    const priorWidth = sizesMap?.[modelName]?.width;

    let fittingWidth = priorWidth;

    if (axes) {
      if (axes === "yLeft" || axes === "yRight") {
        fittingWidth = fittingWidth / 2;
      }

      if (axes === "yRight") {
        fittingWidth = [];
        fittingWidth.push(priorWidth / 2);
        fittingWidth.push(priorWidth);
      }

      if (axes === "yDouble") {
        fittingWidth = fittingWidth * 2;
      }

      if (axes !== "x") {
        if (fittingWidth == 0.3) {
          setShowCabinets(false);
          setShowDrawers(false);
          setShowShelves(true);
        } else if (fittingWidth == 0.4) {
          setShowShelves(false);
          setShowDrawers(false);
          setShowCabinets(true);
        } else if (fittingWidth == 0.6) {
          setShowShelves(false);
          setShowCabinets(true);
          setShowDrawers(true);
        } else if (fittingWidth == 1.2) {
          setShowCabinets(false);
          setShowDrawers(true);
          setShowShelves(true);
        } else if (Array.isArray(fittingWidth) && fittingWidth.includes(0.3)) {
          setShowShelves(true);
        } else if (Array.isArray(fittingWidth) && fittingWidth.includes(0.4)) {
          setShowCabinets(true);
        } else if (Array.isArray(fittingWidth) && fittingWidth.includes(0.6)) {
          setShowCabinets(true);
          setShowDrawers(true);
        } else if (Array.isArray(fittingWidth) && fittingWidth.includes(1.2)) {
          setShowDrawers(true);
          setShowShelves(true);
        } else {
          setShowCabinets(true);
          setShowShelves(true);
          setShowDrawers(true);
        }
      } else {
        setShowCabinets(true);
        setShowShelves(true);
        setShowDrawers(true);
      }
    } else {
      setShowCabinets(true);
      setShowShelves(true);
      setShowDrawers(true);
    }
  }, [axes, indexOfModel, elements]);

  return (
    <>
      <div className={styles.sidebarwrapper}>
        <div className={styles.sidebarwrapper_innercontainer}>
          {showSidebar && viewportWidth < 1400 && (
            <div
              onClick={() => setShowSidebar(false)}
              className={styles.sidebarArrow}
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/sidebarArrow.svg"}
              />
            </div>
          )}

          <h2>Desaro</h2>

          <div className={styles.buttonsTopRow}>
            <button
              className={`${btnStyles.sidebarSelection} ${
                sidebarMainOption === "Aufbau"
                  ? btnStyles.activeSidebarSelection
                  : null
              }`}
              onClick={() => {
                setSidebarMainOption("Aufbau");
              }}
            >
              Aufbau
            </button>
            <button
              className={`${btnStyles.sidebarSelection} ${
                sidebarMainOption === "Farben"
                  ? btnStyles.activeSidebarSelection
                  : null
              }`}
              onClick={() => {
                setSidebarMainOption("Farben");
              }}
            >
              Farben & Material
            </button>
            <button
              className={`${btnStyles.sidebarSelection} ${
                sidebarMainOption === "Optionen"
                  ? btnStyles.activeSidebarSelection
                  : null
              }`}
              onClick={() => {
                setSidebarMainOption("Optionen");
              }}
            >
              Optionen & Extras
            </button>
          </div>

          <>
            {sidebarMainOption === "Aufbau" ? (
              <div className={styles.elementTypeSelection}>
                <p className="semiBold">Wähle ein Element aus</p>

                {showCabinets ? (
                  <>
                    <div>
                      <p>Schränke</p>
                    </div>
                    <hr />
                    <div className={styles.elementsContainer}>
                      <Cabinets />
                    </div>
                  </>
                ) : null}

                {showShelves ? (
                  <>
                    <div>
                      <p>Regale</p>
                    </div>
                    <hr />
                    <div className={styles.elementsContainer}>
                      <Shelves />
                    </div>
                  </>
                ) : null}

                {showDrawers ? (
                  <>
                    <div>
                      <p>Schubladen</p>
                    </div>
                    <hr />
                    <div
                      className={`${styles.elementsContainer} ${styles.elementsContainerDrawers}`}
                    >
                      <Drawers />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            <SidebarElements models={models} />
          </>

          {sidebarMainOption === "Farben" ? (
            <>
              <p className="semiBold">
                Wähle ein Bauteil und dann das Material
              </p>
              <MaterialsOverview />
            </>
          ) : null}

          {sidebarMainOption === "Optionen" ? (
            <>
              <p className="semiBold">Aktiviere eine Option oder Extra</p>
              {indexOfModel !== null &&
              !elements?.[indexOfModel]?.model.startsWith("Model_15") &&
              !elements?.[indexOfModel]?.model.startsWith("Model_16") &&
              !elements?.[indexOfModel]?.model.startsWith("Model_17") &&
              !elements?.[indexOfModel]?.model.startsWith("Model_18") &&
              !axes ? (
                <>
                  <CurrentElement />
                  <p className="semiBold">Eigenschaften</p>
                  <div className={styles.sidebarOptions}>
                    {hasLED &&
                      elements?.[indexOfModel]?.model.includes("led") && (
                        <div
                          className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                          onClick={() => {
                            handleLedChange();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/desaro-led.jpg"
                            }
                            alt="LED entfernen"
                          />
                          <p>LED Beleuchtung</p>
                        </div>
                      )}
                    {hasLED &&
                      elements?.[indexOfModel]?.model.includes("led") ===
                        false && (
                        <div
                          className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                          onClick={() => {
                            handleLedChange();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/desaro-led.jpg"
                            }
                            alt="LED hinzufügen"
                            className={styles.greyscale}
                          />
                          <p>LED Beleuchtung</p>
                        </div>
                      )}

                    {canShowLeftScharniere && (
                      <div
                        className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                        onClick={() => {
                          handleScharniereLinks();
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/desaro-tuer-l.jpg"
                          }
                          alt="Türscharniere links entfernen"
                          className={`${
                            elements?.[indexOfModel]?.model.startsWith(
                              "Model_12"
                            )
                              ? styles.greyscale
                              : null
                          }`}
                        />
                        <p>Türscharniere links</p>
                      </div>
                    )}

                    {canShowRightScharniere && (
                      <div
                        className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                        onClick={() => {
                          handleScharniereRechts();
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/desaro-tuer-r.jpg"
                          }
                          className={`${
                            elements?.[indexOfModel]?.model.startsWith(
                              "Model_11"
                            )
                              ? styles.greyscale
                              : null
                          }`}
                          alt="Türscharniere rechts hinzufügen"
                        ></img>
                        <p>Türscharniere rechts</p>
                      </div>
                    )}

                    {canShowModifications && (
                      <>
                        <div
                          className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                          onClick={() => {
                            handleFachboeden();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/fachboeden.jpg"
                            }
                            alt="Fachböden einsetzen"
                            className={`${styles.greyscale} ${
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_11031"
                              ) ||
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_12031"
                              )
                                ? styles.colored
                                : null
                            }`}
                          />
                          <p>Fachböden</p>
                        </div>

                        <div
                          className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                          onClick={() => {
                            handleKleiderstange();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/kleiderstange.jpg"
                            }
                            alt="Kleiderstange einsetzen"
                            className={`${styles.greyscale} ${
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_11032"
                              ) ||
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_12032"
                              )
                                ? styles.colored
                                : null
                            }`}
                          />
                          <p>Kleiderstange</p>
                        </div>

                        <div
                          className={`${styles.sidebarOption} ${stylesConfigurator.pointer}`}
                          onClick={() => {
                            handleKleiderlift();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/kleiderlift.jpg"
                            }
                            alt="Kleiderlift einsetzen"
                            className={`${styles.greyscale} ${
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_11033"
                              ) ||
                              elements?.[indexOfModel]?.model.startsWith(
                                "Model_12033"
                              )
                                ? styles.colored
                                : null
                            }`}
                          />
                          <p>Kleiderlift</p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <p>Wähle hierzu bitte ein Model in der 3D-Ansicht aus!</p>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.configurationwrapper}>
        <div className={styles.configurationwrapper_innercontainer}>
          <div className={styles.price}>
            <span className={styles.sum}>{price} €</span>
            <span className={styles.tax}>inkl. 19% MwSt. *</span>
          </div>

          {isHeightEqual && elements.length > 3 ? (
            <button
              className={btnStyles.buyButton}
              onClick={() => {
                setShowStockOverview(true);
              }}
            >
              Ansehen
            </button>
          ) : (
            <>
              <button className={btnStyles.buyButtonTransparent}>
                Ansehen
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;

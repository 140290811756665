import React from "react";
import styles from "../../styles/configurator.module.scss";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import sizesMap from "./sizesMap";

const SizesInformation = (models) => {
  const { elements, indexOfModel, completeWidth, totalHeight, alreadyBought } =
    useGlobalContext();

  // let leftRowHeight;

  // if (raycasterRowIntersections?.length) {
  //   let lastIndex = raycasterRowIntersections[0].length - 1;
  //   leftRowHeight =
  //     raycasterRowIntersections[0][lastIndex]?.distance.toFixed(3);
  // }

  let seitenWangenCount = 0;
  for (let i = 0; i < elements.length; i++) {
    if (elements[i]?.model?.startsWith("Model_17")) {
      seitenWangenCount++;
    }
  }

  // let totalHeight;
  // if (elements[2]) {
  //   totalHeight = parseFloat(
  //     sizesMap[elements[2]?.model]?.height * 100
  //   ).toFixed(0);
  //   if (seitenWangenCount > 1) {
  //   }
  // }
  // setTotalHeight(totalHeight);

  return (
    <div
      className={`${styles.sizes} ${
        alreadyBought === true ? styles.fullWidth : null
      }`}
    >
      {totalHeight !== undefined && completeWidth && elements ? (
        <span>
          <strong>Maße Gesamt: </strong>
          {(completeWidth * 100 + 4).toFixed(0)}cm x{" "}
          {(totalHeight * 100).toFixed(0)}cm x 60cm (BHT)
        </span>
      ) : (
        <span>
          <strong>Maße Gesamt: </strong>Kein Element platziert
        </span>
      )}

      {indexOfModel !== undefined && indexOfModel !== null ? (
        <>
          <span>
            <strong>Ausgewähltes Element:</strong>{" "}
            {(sizesMap[elements[indexOfModel]?.model]?.width * 100).toFixed(0)}
            cm x{" "}
            {(sizesMap[elements[indexOfModel]?.model]?.height * 100).toFixed(0)}
            cm x 60cm (BHT)
          </span>
        </>
      ) : (
        <span>
          <strong>Ausgewähltes Element:</strong> Kein Element ausgewählt{" "}
        </span>
      )}
    </div>
  );
};

export default SizesInformation;

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../../contextProviders/ContextProvider";
import { RepeatWrapping } from "three";

export function Model_140311_600x4927_fb_led(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/14/03/140311_600x4927_fb_led.glb"
  );

  const { index } = props;
  const { elementsMaterials } = useGlobalContext();

  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;
  korpusTexture.wrapS = RepeatWrapping;
  korpusTexture.wrapT = RepeatWrapping;

  const ledMaterial = new THREE.MeshStandardMaterial({
    emissive: new THREE.Color(0xffe2c5),
    emissiveIntensity: 2,
  });

  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        <mesh geometry={nodes.Objekt_2.geometry} material={ledMaterial} />
        <mesh geometry={nodes.Objekt_3.geometry} material={ledMaterial} />
      </group>
      <mesh
        geometry={nodes.Objekt_1.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        scale={0.001}
      />
    </group>
  );
}

import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useGlobalContext } from "../../../../contextProviders/ContextProvider";
import { RepeatWrapping } from "three";

export function Model_120511_400x1548_fb_led(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/12/05/120511_400x1548_fb_led.glb"
  );

  const { index } = props;
  const { elementsMaterials, showFronten } = useGlobalContext();

  const frontTexture = useTexture(elementsMaterials[index]?.front);
  const korpusTexture = useTexture(elementsMaterials[index]?.korpus);

  frontTexture.colorSpace = THREE.SRGBColorSpace;
  frontTexture.needsUpdate = true;

  korpusTexture.colorSpace = THREE.SRGBColorSpace;
  korpusTexture.needsUpdate = true;

  frontTexture.wrapS = RepeatWrapping;
  frontTexture.wrapT = RepeatWrapping;

  const ledMaterial = new THREE.MeshStandardMaterial({
    emissive: new THREE.Color(0xffe2c5),
    emissiveIntensity: 2,
  });

  return (
    <group {...props} dispose={null}>
      <group scale={0.001}>
        <mesh
          geometry={nodes.brep_10.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_14.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_16.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_18.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_22.geometry}
          material={materials.Scharniere}
        />
        <mesh
          geometry={nodes.brep_24.geometry}
          material={materials.Scharniere}
        />
      </group>
      <group scale={0.001}>
        <mesh geometry={nodes.brep_7.geometry} material={ledMaterial} />
        <mesh geometry={nodes.brep_8.geometry} material={ledMaterial} />
      </group>
      <mesh
        geometry={nodes.brep_33.geometry}
        material={new THREE.MeshStandardMaterial({ map: korpusTexture })}
        scale={0.001}
      />
      {showFronten ? (
        <mesh
          geometry={nodes.brep_31.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
        />
      ) : (
        <mesh
          geometry={nodes.brep_31.geometry}
          material={new THREE.MeshStandardMaterial({ map: frontTexture })}
          scale={0.001}
          visible={false}
        />
      )}
    </group>
  );
}

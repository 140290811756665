import React, { Suspense, useEffect } from "react";
import styles from "../../styles/configurator.module.scss";
import sidebarStyles from "../../styles/sidebar.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import sizesMap from "./sizesMap";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import ActiveMaterial from "./ActiveMaterial";
import MaterialSelection from "./MaterialSelection";
import LoadingAnimation from "./LoadingObserver";

const MaterialsOverview = () => {
  const {
    elements,
    setShowMaterialOverview,
    elementsMaterials,
    indexOfModel,
    frontenTexture,
    korpusTexture,
    selectedAll,
    setSelectedAll,
    isSelectedElement,
  } = useGlobalContext();

  const handleCloseMaterialsOverview = () => {
    setShowMaterialOverview(false);
    setSelectedAll(false);
  };

  useEffect(() => {
    if (elements?.[indexOfModel]?.model.startsWith("Model_14")) {
      setSelectedAll(false);
    }
  }, [indexOfModel, elements]);

  return (
    <div className={styles.materialsOverviewContainer}>
      <div className={styles.materialsOverviewContainerHeadline}>
        <h3>Farben und Materialien</h3>
      </div>
      <div className={styles.materialsOverviewElementSelection}>
        {/* {selectedAll ||
        (elementsMaterials[indexOfModel]?.front != null &&
          !elements?.[indexOfModel]?.model?.startsWith("Model_17")) ? (
          <ActiveMaterial element={"Fronten"} texture={frontenTexture} />
        ) : null} */}

        <ActiveMaterial element={"Fronten"} texture={frontenTexture} />
        <ActiveMaterial element={"Korpus"} texture={korpusTexture} />
        <ActiveMaterial element={"Griffleisten"} />
        <ActiveMaterial element={"Sockel"} />
        <ActiveMaterial element={"Seitenwangen"} />
      </div>
      {selectedAll && (
        <p className={styles.korpusNote}>
          <strong>Hinweis:</strong> Offene Elemente verfügen über weitere
          Korpusmaterialien, wenn diese separat ausgewählt werden.
        </p>
      )}

      <p className="semiBold">Anwenden für</p>
      <div className={sidebarStyles.buttonsTopRow}>
        {!elements?.[indexOfModel]?.model.startsWith("Model_14") ? (
          <button
            className={`${btnStyles.sidebarSelection} ${
              selectedAll === true ? btnStyles.activeSidebarSelection : null
            }`}
            onClick={() => {
              setSelectedAll(true);
            }}
          >
            alle Elemente
          </button>
        ) : (
          <button
            className={`${btnStyles.sidebarSelection} ${sidebarStyles.transparentModel}`}
          >
            alle Elemente
          </button>
        )}

        {isSelectedElement === "Fronten" ||
        isSelectedElement === "Korpus" ||
        (isSelectedElement === "Griffleisten" &&
          elements?.[indexOfModel]?.model.startsWith("Model_16")) ? (
          <button
            className={`${btnStyles.sidebarSelection} ${
              selectedAll === false ? btnStyles.activeSidebarSelection : null
            }`}
            onClick={() => {
              setSelectedAll(false);
            }}
          >
            Auswahl
          </button>
        ) : null}
      </div>

      <div>
        <MaterialSelection />
      </div>
    </div>
  );
};

export default MaterialsOverview;

import React from "react";
import gifAnimation from "../../animation/HAL_9999_Loading-Animation_3.gif";
import styles from "../../styles/configurator.module.scss";

const LoadingAnimation = () => {
  return (
    <div className={styles.animationContainer}>
      <img
        src={
          process.env.PUBLIC_URL + "/images/HAL_9999_Loading-Animation_3.gif"
        }
        alt="Es wird geladen..."
      />
    </div>
  );
};

export default LoadingAnimation;

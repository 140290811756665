import React, { useEffect } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import inventory from "./inventory.json";

const Prices = () => {
  const { variantSkus, setPrice } = useGlobalContext();

  useEffect(() => {
    let totalPrice = 0;
    variantSkus.forEach((sku) => {
      const foundItem = inventory.find((item) => item["Variant SKU"] == sku);
      if (foundItem) {
        const price = foundItem["Variant Price"] || 0; // Get the price, default to 0 if not present
        totalPrice += price;
      }
    });
    setPrice(totalPrice.toFixed(2));
  }, [variantSkus]);

  return null;
};

export default Prices;

import React, { useEffect, useRef } from "react";
import { Box3, BoxHelper } from "three";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import { EdgesGeometry, LineBasicMaterial, LineSegments } from "three";
import * as THREE from "three";

const ModelOutline = () => {
  const { currentSelectedModelRef, boundingBox, setBoundingBox } =
    useGlobalContext();

  const meshRef = useRef();

  // Update the position and dimensions of the mesh and wireframe when the boundingBox changes
  useEffect(() => {
    if (meshRef.current && boundingBox) {
      const { min, max } = boundingBox;

      // Update position
      meshRef.current.position.set(
        (min.x + max.x) / 2,
        (min.y + max.y) / 2,
        (min.z + max.z) / 2
      );

      // Update scale
      meshRef.current.scale.set(max.x - min.x, max.y - min.y, max.z - min.z);
    }
  }, [boundingBox]);

  return (
    <group>
      <mesh ref={meshRef}>
        <boxGeometry args={[1.001, 1.001, 1.001]} />
        <meshBasicMaterial color="#0000ff" transparent opacity={0.2} />
      </mesh>
    </group>
  );
};

export default ModelOutline;

import React, { useEffect } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import { Vector3 } from "three";

const SceneProperties = ({ element, models, orbitRef }) => {
  const { scene } = useThree();
  const { elementToDelete } = useGlobalContext();

  useFrame(() => {
    const { object } = orbitRef.current;

    const minX = -3;
    const maxX = 6;
    const minY = 0;
    const maxY = 2;
    const minZ = 1;
    const maxZ = 5;

    // Clamp the camera position to the defined limits
    object.position.x = Math.max(minX, Math.min(maxX, object.position.x));
    object.position.y = Math.max(minY, Math.min(maxY, object.position.y));
    object.position.z = Math.max(minZ, Math.min(maxZ, object.position.z));

    const controls = orbitRef.current;

    const { x, y, z } = controls.target;
    controls.target.x = Math.max(minX, Math.min(maxX, x));
    controls.target.y = Math.max(minY, Math.min(maxY, y));
    controls.target.z = Math.max(minZ, Math.min(maxZ, z));
  });

  if (elementToDelete.length) {
    elementToDelete.forEach((e) => {
      scene.remove(models?.current[e]);
    });
  }
  return;
};

export default SceneProperties;

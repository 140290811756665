import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./contextProviders/ContextProvider";
import styles from "./index.scss";
import LoadingAnimation from "./components/configurator/LoadingAnimation";
import LoadingObserver from "./components/configurator/LoadingObserver";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <App className={styles.App} />
    </ContextProvider>
  </React.StrictMode>
);

reportWebVitals();

import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import styles from "../../styles/sidebar.module.scss";
import sizesMap from "./sizesMap";
import configuratorStyles from "../../styles/configurator.module.scss";

const Drawers = () => {
  const {
    elementToAdd,
    setElementToAdd,
    selectedModelWidth,
    axes,
    indexOfModel,
    setElementAdded,
  } = useGlobalContext();
  const [allowedWidth, setAllowedWidth] = useState();

  useEffect(() => {
    if (axes === "yLeft") {
      setAllowedWidth(selectedModelWidth * 0.5);
    }
    if (axes === "yRight") {
      let allowedWidths = [];
      allowedWidths.push(selectedModelWidth * 0.5);
      allowedWidths.push(selectedModelWidth);
      setAllowedWidth(allowedWidths);
    }
    if (axes === "yDouble") {
      setAllowedWidth(selectedModelWidth * 2);
    }
    if (axes === "y") {
      setAllowedWidth(selectedModelWidth);
    }
    if (axes === "x" || axes === null) {
      setAllowedWidth(0);
    }
  }, [selectedModelWidth, axes, indexOfModel]);

  return (
    <>
      {sizesMap["Model_130110_600x19133_schub"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_130110_600x19133_schub"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_130110_600x19133_schub")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/schubkaesten/130110.jpg"
            }
            alt="Modell 130110"
          />
          <p>60cm x 22cm x 60cm</p>
          {elementToAdd === "Model_130110_600x19133_schub" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_130110_600x19133_schub");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/60cm/schubkaesten/130110.jpg"
            }
            alt="Modell 130110"
          />
          <p>60cm x 22cm x 60cm</p>
        </div>
      )}

      {sizesMap["Model_130210_1200x19133_schub"]?.width == allowedWidth ||
      allowedWidth === 0 ||
      allowedWidth === undefined ||
      (Array.isArray(allowedWidth) &&
        allowedWidth.includes(
          sizesMap["Model_130210_1200x19133_schub"]?.width
        )) ? (
        <div
          className={`${styles.elementsWrapper} ${configuratorStyles.pointer}`}
          onClick={() => setElementToAdd("Model_130210_1200x19133_schub")}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/120cm/schubkaesten/130210.jpg"
            }
            alt="Modell 130110"
          />
          <p>120cm x 22cm x 60cm</p>
          {elementToAdd === "Model_130210_1200x19133_schub" && (
            <div
              className={styles.addSymbol}
              onClick={() => {
                setElementAdded("Model_130210_1200x19133_schub");
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/circle-plus-solid-white.svg"
                }
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${styles.elementsWrapper} ${styles.transparentModel}`}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/elements/120cm/schubkaesten/130210.jpg"
            }
            alt="Modell 130110"
          />
          <p>120cm x 22cm x 60cm</p>
        </div>
      )}
    </>
  );
};

export default Drawers;

import React, { useEffect } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";

const Inventory = () => {
  const {
    elements,
    setElements,
    elementsMaterials,
    setElementsMaterials,
    setVariantSkus,
  } = useGlobalContext();

  useEffect(() => {
    // generate modelNumber
    let modelNumbers = [];
    let modelNumber = elements.map((element) => {
      let trimmedNumber = element.model.substring(6, 12);
      modelNumbers.push(trimmedNumber);
    });

    // generate MaterialNumber

    let materialNumbers = [];
    let korpus;
    let front;

    let materialNumber = elementsMaterials.map((material) => {
      let materialKorpus = material.korpus;
      let materialFront = material.front;

      switch (true) {
        case materialKorpus.includes("standard_weiss"):
          korpus = "01";
          break;
        case materialKorpus.includes("vulkanschwarz"):
          korpus = "02";
          break;
        case materialKorpus.includes("cuvo"):
          korpus = "03";
          break;
        case materialKorpus.includes("sandbeige"):
          korpus = "04";
          break;
        case materialKorpus.includes("nussbaum_optik"):
          korpus = "05";
          break;
        case materialKorpus.includes("eiche_astig"):
          korpus = "06";
          break;
        case materialKorpus.includes("lichtgrau"):
          korpus = "07";
          break;
        case materialKorpus.includes("antrazithgrau"):
          korpus = "08";
          break;
        case materialKorpus.includes("eichefurnier"):
          korpus = "09";
          break;
        case materialKorpus.includes("nussbaumfurnier"):
          korpus = "10";
          break;
        case materialKorpus.includes("alpin_weiss"):
          korpus = "11";
          break;
        case materialKorpus.includes("12_schwarz"):
          korpus = "12";
          break;
        case materialKorpus.includes("13_seidengrau"):
          korpus = "13";
          break;
        case materialKorpus.includes("14_quarzgrau"):
          korpus = "14";
          break;
        case materialKorpus.includes("perlkupfer"):
          korpus = "15";
          break;
        case materialKorpus.includes("16_fernblau"):
          korpus = "16";
          break;
        case materialKorpus.includes("rapsgelb"):
          korpus = "17";
          break;
        case materialKorpus.includes("resedagruen"):
          korpus = "18";
          break;
        case materialKorpus.includes("19_aluminium"):
          korpus = "19";
          break;
        default:
          korpus = "00";
      }

      switch (true) {
        case materialFront?.includes("standard_weiss"):
          front = "01";
          break;
        case materialFront?.includes("vulkanschwarz"):
          front = "02";
          break;
        case materialFront?.includes("cuvo"):
          front = "03";
          break;
        case materialFront?.includes("sandbeige"):
          front = "04";
          break;
        case materialFront?.includes("nussbaum_optik"):
          front = "05";
          break;
        case materialFront?.includes("eiche_astig"):
          front = "06";
          break;
        case materialFront?.includes("lichtgrau"):
          front = "07";
          break;
        case materialFront?.includes("antrazithgrau"):
          front = "08";
          break;
        case materialFront?.includes("eichefurnier"):
          front = "09";
          break;
        case materialFront?.includes("nussbaumfurnier"):
          front = "10";
          break;
        case materialFront?.includes("alpin_weiss"):
          front = "11";
          break;
        case materialFront?.includes("12_schwarz"):
          front = "12";
          break;
        case materialFront?.includes("13_seidengrau"):
          front = "13";
          break;
        case materialFront?.includes("14_quarzgrau"):
          front = "14";
          break;
        case materialFront?.includes("perlkupfer"):
          front = "15";
          break;
        case materialFront?.includes("16_fernblau"):
          front = "16";
          break;
        case materialFront?.includes("rapsgelb"):
          front = "17";
          break;
        case materialFront?.includes("resedagruen"):
          front = "18";
          break;
        case materialFront?.includes("19_aluminium"):
          front = "19";
          break;
        default:
          front = "00";
      }

      let combinedMaterial = `${korpus}${front}`;

      materialNumbers.push(combinedMaterial);
    });

    if (modelNumbers.length === materialNumbers.length) {
      let concatenatedSku = [];
      for (let i = 0; i < modelNumbers.length; i++) {
        let combinedValue = modelNumbers[i] + "-" + materialNumbers[i];
        concatenatedSku.push(combinedValue);
      }
      setVariantSkus(concatenatedSku);
    }
  }, [elements, elementsMaterials]);

  return null;
};

export default Inventory;

const sizesMap = {
  /* 11/01 */
  Model_110110_600x734_fb: {
    width: 0.6,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  Model_110111_600x734_fb_led: {
    width: 0.6,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  /* 11/02 */
  Model_110210_600x493_fb: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: true,
  },
  Model_110211_600x493_fb_led: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: true,
  },
  /* 11/03 */
  Model_110310_600x1548_fb: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_110311_600x1548_fb_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_110320_600x1548_kl: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
    hasRail: true,
  },
  Model_110321_600x1548_kl_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
    hasRail: true,
  },
  Model_110330_600x1548_klf: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
    hasLift: true,
  },
  Model_110331_600x1548_klf_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
    hasLift: true,
  },
  /* 11/04 */
  Model_110410_400x734_fb: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  Model_110411_400x734_fb_led: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  /* 11/05 */
  Model_110510_400x1548_fb: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_110511_400x1548_fb_led: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  /* 12/01 */
  Model_120110_600x734_fb: {
    width: 0.6,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  Model_120111_600x734_fb_led: {
    width: 0.6,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  /* 12/02 */
  Model_120210_600x493_fb: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: true,
  },
  Model_120211_600x493_fb_led: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: true,
  },
  /* 12/03 */
  Model_120310_600x1548_fb: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120311_600x1548_fb_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120320_600x1548_kl: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120321_600x1548_kl_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120330_600x1548_klf: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120331_600x1548_klf_led: {
    width: 0.6,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  /* 12/04 */
  Model_120410_400x734_fb: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  Model_120411_400x734_fb_led: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: true,
  },
  /* 12/05 */
  Model_120510_400x1548_fb: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  Model_120511_400x1548_fb_led: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: true,
  },
  /* 13/01 */
  Model_130110_600x19133_schub: {
    width: 0.6,
    height: 0.21933,
    hasEdge: true,
    hasFront: true,
  },
  Model_130111_600x19133_schub_led: {
    width: 0.6,
    height: 0.21933,
    hasEdge: true,
    hasFront: true,
  },
  /* 13/02 */
  Model_130210_1200x19133_schub: {
    width: 1.2,
    height: 0.21933,
    hasEdge: true,
    hasFront: true,
  },
  Model_130211_1200x19133_schub_led: {
    width: 1.2,
    height: 0.21933,
    hasEdge: true,
    hasFront: true,
  },
  /* 14/01 */
  Model_140110_300x2362_offen_fb: {
    width: 0.3,
    height: 2.392,
    hasEdge: true,
    hasFront: false,
  },
  Model_140111_300x2362_offen_fb_led: {
    width: 0.3,
    height: 2.392,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/02 */
  Model_140210_300x21207_offen: {
    width: 0.3,
    height: 2.12067,
    hasEdge: true,
    hasFront: false,
  },
  Model_140211_300x21207_offen_led: {
    width: 0.3,
    height: 2.12067,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/03 */
  Model_140310_600x4927_fb: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: false,
  },
  Model_140311_600x4927_fb_led: {
    width: 0.6,
    height: 0.49266,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/04 */
  Model_140410_600x2213_offen: {
    width: 0.6,
    height: 0.22133,
    hasEdge: true,
    hasFront: false,
  },
  Model_140411_600x2213_offen_led: {
    width: 0.6,
    height: 0.22133,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/05 */
  Model_140510_1200x2213_offen: {
    width: 1.2,
    height: 0.22133,
    hasEdge: true,
    hasFront: false,
  },
  Model_140511_1200x2213_offen_led: {
    width: 1.2,
    height: 0.22133,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/06 */
  Model_140610_400x1548_offen_fb: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: false,
  },
  Model_140611_400x1548_offen_fb_led: {
    width: 0.4,
    height: 1.578,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/07 */
  Model_140710_400x12767_offen_fb: {
    width: 0.4,
    height: 1.3067,
    hasEdge: true,
    hasFront: false,
  },
  Model_140711_400x12767_offen_fb_led: {
    width: 0.4,
    height: 1.3067,
    hasEdge: true,
    hasFront: false,
  },
  /* 14/08 */
  Model_140810_400x764_offen_fb: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: false,
  },
  Model_140811_400x764_offen_fb_led: {
    width: 0.4,
    height: 0.764,
    hasEdge: true,
    hasFront: false,
  },
  /* 15 */
  Model_150100_300: {
    width: 0.3,
    height: 0.08,
  },
  Model_150200_400: {
    width: 0.4,
    height: 0.08,
  },
  Model_150300_600: {
    width: 0.6,
    height: 0.08,
  },
  Model_150400_900: {
    width: 0.9,
    height: 0.08,
  },
  Model_150500_1000: {
    width: 1,
    height: 0.08,
  },
  Model_150600_1200: {
    width: 1.2,
    height: 0.08,
  },
  Model_150700_1500: {
    width: 1.5,
    height: 0.08,
  },
  Model_150800_1600: {
    width: 1.6,
    height: 0.08,
  },
  Model_150900_1800: {
    width: 1.8,
    height: 0.08,
  },
  Model_151000_2100: {
    width: 2.1,
    height: 0.08,
  },
  Model_151100_2200: {
    width: 2.2,
    height: 0.08,
  },
  Model_151200_2400: {
    width: 2.4,
    height: 0.08,
  },
  Model_151300_2700: {
    width: 2.7,
    height: 0.08,
  },
  /* 16 */
  Model_160100_300: {
    width: 0.3,
    height: 0.08,
  },
  Model_160200_400: {
    width: 0.4,
    height: 0.08,
  },
  Model_160300_600: {
    width: 0.6,
    height: 0.08,
  },
  Model_160400_900: {
    width: 0.9,
    height: 0.08,
  },
  Model_160500_1000: {
    width: 1,
    height: 0.08,
  },
  Model_160600_1200: {
    width: 1.2,
    height: 0.08,
  },
  Model_160700_1500: {
    width: 1.5,
    height: 0.08,
  },
  Model_160800_1600: {
    width: 1.6,
    height: 0.08,
  },
  Model_160900_1800: {
    width: 1.8,
    height: 0.08,
  },
  Model_161100_2200: {
    width: 2.2,
    height: 0.08,
  },
  Model_161200_2400: {
    width: 2.4,
    height: 0.08,
  },
  Model_161300_2700: {
    width: 2.7,
    height: 0.08,
  },
  /* 17 */
  Model_170100_28633: {
    width: 0.019,
    height: 0.28633,
  },
  Model_170200_55766: {
    width: 0.019,
    height: 0.55766,
  },
  Model_170300_829: {
    width: 0.019,
    height: 0.829,
  },
  Model_170400_110033: {
    width: 0.019,
    height: 1.10033,
  },
  Model_170500_137166: {
    width: 0.019,
    height: 1.37166,
  },
  Model_170600_1644: {
    width: 0.019,
    height: 1.644,
  },
  Model_170700_191533: {
    width: 0.019,
    height: 1.91533,
  },
  Model_170800_218666: {
    width: 0.019,
    height: 2.18666,
  },
  Model_170900_2457: {
    width: 0.019,
    height: 2.457,
  },
  Model_171000_272833: {
    width: 0.019,
    height: 2.72833,
  },
  /* 18 */
  Model_180100_28633: {
    width: 0.019,
    height: 0.28633,
  },
  Model_180200_55766: {
    width: 0.019,
    height: 0.55766,
  },
  Model_180300_829: {
    width: 0.019,
    height: 0.829,
  },
  Model_180400_110033: {
    width: 0.019,
    height: 1.10033,
  },
  Model_180500_137166: {
    width: 0.019,
    height: 1.37166,
  },
  Model_180600_1644: {
    width: 0.019,
    height: 1.644,
  },
  Model_180700_191533: {
    width: 0.019,
    height: 1.91533,
  },
  Model_180800_218666: {
    width: 0.019,
    height: 2.18666,
  },
  Model_180900_2457: {
    width: 0.019,
    height: 2.457,
  },
  Model_181000_272833: {
    width: 0.019,
    height: 2.72833,
  },
};
export default sizesMap;

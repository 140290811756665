import React from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import sizesMap from "./sizesMap";
import styles from "../../styles/sidebar.module.scss";

const CurrentElement = () => {
  const { elements, indexOfModel } = useGlobalContext();
  return (
    <>
      <div>
        <span className="semiBold">Aktuelles Element: </span>
        <span>{elements?.[indexOfModel]?.model}</span>
      </div>

      <div className={styles.currentElementContainer}>
        <div className={styles.currentElementImageContainer}>
          {/* Cabinets */}
          {elements?.[indexOfModel]?.model === "Model_110410_400x734_fb" ||
          elements?.[indexOfModel]?.model === "Model_110411_400x734_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120410_400x734_fb" ||
          elements?.[indexOfModel]?.model === "Model_120411_400x734_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/40cm/drehtueren/110410.jpg"
              }
              alt="Modell 110410"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110510_400x1548_fb" ||
          elements?.[indexOfModel]?.model === "Model_110511_400x1548_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120511_400x1548_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120510_400x1548_fb" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/40cm/drehtueren/110510.jpg"
              }
              alt="Modell 110510"
            />
          ) : null}

          {elements?.[indexOfModel]?.model ===
            "Model_140610_400x1548_offen_fb" ||
          elements?.[indexOfModel]?.model ===
            "Model_140611_400x1548_offen_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/40cm/offene_elemente/140610.jpg"
              }
              alt="Modell 140610"
            />
          ) : null}

          {elements?.[indexOfModel]?.model ===
            "Model_140710_400x12767_offen_fb" ||
          elements?.[indexOfModel]?.model ===
            "Model_140711_400x12767_offen_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/40cm/offene_elemente/140710.jpg"
              }
              alt="Modell 140710"
            />
          ) : null}

          {elements?.[indexOfModel]?.model ===
            "Model_140810_400x764_offen_fb" ||
          elements?.[indexOfModel]?.model ===
            "Model_140811_400x764_offen_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/40cm/offene_elemente/140810.jpg"
              }
              alt="Modell 140810"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110110_600x734_fb" ||
          elements?.[indexOfModel]?.model === "Model_110111_600x734_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120111_600x734_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120110_600x734_fb" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/drehtueren/110110.jpg"
              }
              alt="Modell 110110"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110210_600x493_fb" ||
          elements?.[indexOfModel]?.model === "Model_110211_600x493_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120211_600x493_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120210_600x493_fb" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/drehtueren/110210.jpg"
              }
              alt="Modell 110210"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110310_600x1548_fb" ||
          elements?.[indexOfModel]?.model === "Model_110311_600x1548_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120311_600x1548_fb_led" ||
          elements?.[indexOfModel]?.model === "Model_120310_600x1548_fb" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/drehtueren/110310.jpg"
              }
              alt="Modell 110310"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110320_600x1548_kl" ||
          elements?.[indexOfModel]?.model === "Model_110321_600x1548_kl_led" ||
          elements?.[indexOfModel]?.model === "Model_120321_600x1548_kl_led" ||
          elements?.[indexOfModel]?.model === "Model_120320_600x1548_kl" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/drehtueren/110320.jpg"
              }
              alt="Modell 110320"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_110330_600x1548_klf" ||
          elements?.[indexOfModel]?.model === "Model_110331_600x1548_klf_led" ||
          elements?.[indexOfModel]?.model === "Model_120331_600x1548_klf_led" ||
          elements?.[indexOfModel]?.model === "Model_120330_600x1548_klf" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/drehtueren/110330.jpg"
              }
              alt="Modell 110330"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_140310_600x4927_fb" ||
          elements?.[indexOfModel]?.model === "Model_140311_600x4927_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/offene_elemente/140310.jpg"
              }
              alt="Modell 140310"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_140410_600x2213_offen" ||
          elements?.[indexOfModel]?.model ===
            "Model_140411_600x2213_offen_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/offene_elemente/140410.jpg"
              }
              alt="Modell 140410"
            />
          ) : null}

          {/* Shelves */}

          {elements?.[indexOfModel]?.model ===
            "Model_140110_300x2362_offen_fb" ||
          elements?.[indexOfModel]?.model ===
            "Model_140111_300x2362_offen_fb_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/30cm/offene_elemente/140110.jpg"
              }
              alt="Modell 140110"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_140210_300x21207_offen" ||
          elements?.[indexOfModel]?.model ===
            "Model_140211_300x21207_offen_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/30cm/offene_elemente/140210.jpg"
              }
              alt="Modell 140210"
            />
          ) : null}

          {elements?.[indexOfModel]?.model === "Model_140510_1200x2213_offen" ||
          elements?.[indexOfModel]?.model ===
            "Model_140511_1200x2213_offen_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/120cm/offene_elemente/140510.jpg"
              }
              alt="Modell 140510"
            />
          ) : null}

          {/* Drawers */}

          {elements?.[indexOfModel]?.model === "Model_130110_600x19133_schub" ||
          elements?.[indexOfModel]?.model ===
            "Model_130111_600x19133_schub_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/60cm/schubkaesten/130110.jpg"
              }
              alt="Modell 130110"
            />
          ) : null}

          {elements?.[indexOfModel]?.model ===
            "Model_130210_1200x19133_schub" ||
          elements?.[indexOfModel]?.model ===
            "Model_130211_1200x19133_schub_led" ? (
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/elements/120cm/schubkaesten/130210.jpg"
              }
              alt="Modell 130110"
            />
          ) : null}
        </div>
        <div>
          <p className={`semiBold ${styles.currentSizesCaptionFont}`}>Maße: </p>
          <p className={styles.currentSizesFont}>
            {(sizesMap[elements[indexOfModel]?.model]?.width * 100).toFixed(0)}
            cm x{" "}
            {(sizesMap[elements[indexOfModel]?.model]?.height * 100).toFixed(0)}
            cm x 60cm
          </p>
        </div>
      </div>
    </>
  );
};

export default CurrentElement;

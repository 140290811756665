import React, { Suspense, useEffect, useState } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import imagepaths from "../imagepaths/imagepaths";
import styles from "../../styles/configurator.module.scss";
import AllFrontenMaterials from "./AllFrontenMaterials";
import KunststoffFrontenMaterials from "./KunststoffFrontenMaterials";
import EchtholzLackFrontenMaterials from "./EchtholzLackFrontenMaterials";
import LoadingAnimation from "./LoadingAnimation";

const MaterialSelection = () => {
  const {
    elements,
    isSelectedElement,
    setIsSelectedElement,
    setFrontenTexture,
    setGlobalFrontenTexture,
    setGlobalKorpusTexture,
    setGlobalGriffleistenTexture,
    setGlobalSockelTexture,
    setGlobalSeitenwangenTexture,
    indexOfModel,
    elementsMaterials,
    setElementsMaterials,
    setKorpusTexture,
    selectedAll,
    globalKorpusTexture,
    globalGriffleistenTexture,
    globalSockelTexture,
    korpusTexture,
    setGriffleistenTexture,
  } = useGlobalContext();

  const [materialType, setMaterialType] = useState("alle");

  useEffect(() => {
    if (!elementsMaterials[indexOfModel]?.front) {
      setIsSelectedElement("Korpus");
    }
  }, [indexOfModel]);

  const changeTexture = (texture) => {
    // Code for changing each material of elements at once.
    if (selectedAll) {
      switch (isSelectedElement) {
        case "Fronten":
          setGlobalFrontenTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item) => {
              if (item.front !== null) {
                return { ...item, front: texture };
              }
              return item;
            });
          });
          break;

        case "Korpus":
          setGlobalKorpusTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item) => {
              if (
                item.griffleiste === false &&
                item.sockel === false &&
                item.wange === false
              ) {
                return { ...item, korpus: texture };
              }
              return item;
            });
          });
          break;

        case "Griffleisten":
          setGlobalGriffleistenTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item) => {
              if (item.griffleiste === true) {
                return { ...item, korpus: texture };
              }
              return item;
            });
          });
          break;

        case "Sockel":
          setGlobalSockelTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item) => {
              if (item.sockel === true) {
                return { ...item, korpus: texture };
              }
              return item;
            });
          });
          break;

        case "Seitenwangen":
          setGlobalSeitenwangenTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item) => {
              if (item.wange === true) {
                return { ...item, korpus: texture };
              }
              return item;
            });
          });
          break;

        default:
          break;
      }
    } else {
      // code for individual material selection or seitenwangen selection
      switch (isSelectedElement) {
        case "Fronten":
          setFrontenTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item, index) => {
              if (index === indexOfModel) {
                return { ...item, front: texture };
              }
              return item;
            });
          });
          break;

        case "Korpus":
          if (elements?.[indexOfModel]?.model?.startsWith("Model_17")) {
            const matchingIndices = [];
            elements.forEach((element, index) => {
              if (
                element.model.startsWith("Model_17") ||
                element.model.startsWith("Model_18")
              ) {
                matchingIndices.push(index);
              }
            });

            if (matchingIndices.length > 0) {
              setKorpusTexture(texture);

              setElementsMaterials((prevArray) => {
                return prevArray.map((item, index) => {
                  if (matchingIndices.includes(index)) {
                    return { ...item, korpus: texture };
                  }
                  return item;
                });
              });
            }
            break;
          } else {
            if (!elements?.[indexOfModel]?.model?.startsWith("Model_16")) {
              setKorpusTexture(texture);
              setElementsMaterials((prevArray) => {
                return prevArray.map((item, index) => {
                  if (index === indexOfModel) {
                    return { ...item, korpus: texture };
                  }
                  return item;
                });
              });
            }
            break;
          }

        case "Griffleisten":
          setGriffleistenTexture(texture);

          setElementsMaterials((prevArray) => {
            return prevArray.map((item, index) => {
              if (index === indexOfModel) {
                return { ...item, korpus: texture };
              }
              return item;
            });
          });
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      {selectedAll === false &&
      (indexOfModel === null || indexOfModel === undefined) ? (
        <p>
          Wähle für eine spezifische Auswahl ein Model in der 3D-Anischt aus!
        </p>
      ) : (
        <>
          <div className={styles.materialsTypeSelection}>
            <span
              onClick={() => {
                setMaterialType("alle");
              }}
              className={`${styles.pointer} ${
                materialType === "alle" && styles.boldFont
              }`}
            >
              Alle
            </span>
            <span
              onClick={() => {
                setMaterialType("kunststoff");
              }}
              className={`${styles.pointer} ${
                materialType === "kunststoff" && styles.boldFont
              }`}
            >
              Kunststoffoberflächen
            </span>
            {elements?.[indexOfModel]?.model?.startsWith("Model_14") ||
            isSelectedElement !== "Korpus" ? (
              <>
                <span
                  onClick={() => {
                    setMaterialType("echtholzLack");
                  }}
                  className={`${styles.pointer} ${
                    materialType === "echtholzLack" && styles.boldFont
                  }`}
                >
                  Echtholz-/Lackoberflächen
                </span>
              </>
            ) : null}
          </div>

          <div className={styles.materialsSelection}>
            {isSelectedElement === "Fronten" && (
              <>
                {materialType === "alle" && (
                  <AllFrontenMaterials changeTexture={changeTexture} />
                )}
                {materialType === "kunststoff" && (
                  <KunststoffFrontenMaterials changeTexture={changeTexture} />
                )}
                {materialType === "echtholzLack" && (
                  <EchtholzLackFrontenMaterials changeTexture={changeTexture} />
                )}
              </>
            )}

            {isSelectedElement === "Korpus" &&
              (elements?.[indexOfModel]?.model.startsWith("Model_14") ? (
                <>
                  {" "}
                  {materialType === "alle" && (
                    <AllFrontenMaterials changeTexture={changeTexture} />
                  )}
                  {materialType === "kunststoff" && (
                    <KunststoffFrontenMaterials changeTexture={changeTexture} />
                  )}
                  {materialType === "echtholzLack" && (
                    <EchtholzLackFrontenMaterials
                      changeTexture={changeTexture}
                    />
                  )}
                </>
              ) : (
                <>
                  <div>
                    <img
                      src={
                        imagepaths[
                          "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                        ]
                      }
                      alt="weiß"
                      onClick={() =>
                        changeTexture(
                          "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                        )
                      }
                      className={`${styles.pointer} 
              ${
                selectedAll &&
                isSelectedElement === "Korpus" &&
                globalKorpusTexture ===
                  "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                  ? styles.selectedMaterial
                  : ""
              }
              ${
                indexOfModel !== undefined &&
                !selectedAll &&
                isSelectedElement === "Korpus" &&
                korpusTexture ===
                  "https://app-staging.4kantdesign.de/images/Materials/01_standard_weiss.jpg"
                  ? styles.selectedMaterial
                  : ""
              }`}
                    />
                    <p>01 Standard-Weiß</p>
                  </div>

                  <div>
                    <img
                      src={
                        imagepaths[
                          "https://app-staging.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                        ]
                      }
                      alt="vulkanschwarz"
                      onClick={() =>
                        changeTexture(
                          "https://app-staging.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                        )
                      }
                      className={`${styles.pointer} 
                ${
                  selectedAll &&
                  isSelectedElement === "Korpus" &&
                  globalKorpusTexture ===
                    "/images/Materials/02_vulkanschwarz.jpg"
                    ? styles.selectedMaterial
                    : ""
                }
                ${
                  indexOfModel !== undefined &&
                  !selectedAll &&
                  isSelectedElement === "Korpus" &&
                  korpusTexture ===
                    "https://app-staging.4kantdesign.de/images/Materials/02_vulkanschwarz.jpg"
                    ? styles.selectedMaterial
                    : ""
                }`}
                    />
                    <p>02 Vulkanschwarz</p>
                  </div>
                </>
              ))}

            {(isSelectedElement === "Griffleisten" ||
              isSelectedElement === "Sockel") && (
              <>
                {materialType === "alle" && (
                  <>
                    <AllFrontenMaterials changeTexture={changeTexture} />
                    <div>
                      <img
                        src={
                          imagepaths[
                            "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg"
                          ]
                        }
                        alt="aluminium"
                        onClick={() =>
                          changeTexture(
                            "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg"
                          )
                        }
                        className={`${styles.pointer} 
                    ${
                      selectedAll &&
                      isSelectedElement === "Griffleisten" &&
                      globalGriffleistenTexture ===
                        "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg"
                        ? styles.selectedMaterial
                        : ""
                    }
                    ${
                      selectedAll &&
                      isSelectedElement === "Sockel" &&
                      globalSockelTexture ===
                        "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg"
                        ? styles.selectedMaterial
                        : ""
                    }
                    ${
                      indexOfModel !== undefined &&
                      !selectedAll &&
                      isSelectedElement === "Griffleisten" &&
                      korpusTexture ===
                        "https://app-staging.4kantdesign.de/images/Materials/19_aluminium.jpg"
                        ? styles.selectedMaterial
                        : ""
                    }`}
                      />
                      <p>19 Aluminium</p>
                    </div>
                  </>
                )}
                {materialType === "kunststoff" && (
                  <KunststoffFrontenMaterials changeTexture={changeTexture} />
                )}
                {materialType === "echtholzLack" && (
                  <EchtholzLackFrontenMaterials changeTexture={changeTexture} />
                )}
              </>
            )}

            {isSelectedElement === "Seitenwangen" && (
              <>
                {materialType === "alle" && (
                  <>
                    <AllFrontenMaterials changeTexture={changeTexture} />
                  </>
                )}
                {materialType === "kunststoff" && (
                  <KunststoffFrontenMaterials changeTexture={changeTexture} />
                )}
                {materialType === "echtholzLack" && (
                  <EchtholzLackFrontenMaterials changeTexture={changeTexture} />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MaterialSelection;

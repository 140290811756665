import React, {
  useState,
  useEffect,
  useMemo,
  Suspense,
  useCallback,
} from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import { Raycaster, Vector3 } from "three";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";

import styles from "../../styles/sidebar.module.scss";
import stylesConfigurator from "../../styles/configurator.module.scss";
import btnStyles from "../../styles/btnStyles.module.scss";
import sizesMap from "./sizesMap";
import Cabinets from "./Cabinets";
import Shelves from "./Shelves";
import Drawers from "./Drawers";
import griffleistenHelper from "../helper/griffleistenHelper";
import sockelHelper from "../helper/sockelHelper";
import LoadingAnimation from "./LoadingAnimation";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const SidebarElements = ({ models, updateModelRefs }) => {
  const {
    elements,
    setElements,
    elementsMaterials,
    setElementsMaterials,
    indexOfModel,
    setIndexOfModel,
    setEditMode,
    boundingBox,
    setBoundingBox,
    axes,
    elementToAdd,
    setElementToAdd,
    setShowAxesModeX,
    setShowAxesModeY,
    setShowAxesModeYleft,
    setShowAxesModeYright,
    setShowAxesModeYdouble,
    setHeightControls,
    setElementToDelete,
    completeWidth,
    setCompleteWidth,
    totalHeight,
    setElementsRendering,
    elementAdded,
    setElementAdded,
    globalFrontenTexture,
    globalKorpusTexture,
    globalGriffleistenTexture,
    globalSockelTexture,
    globalSeitenwangenTexture,
    setLoading,
    setShowCabinets,
    setShowShelves,
    setShowDrawers,
    setShowSidebar,
    spaceUnderModel,
  } = useGlobalContext();

  const [showXBottomTip, setShowXBottomTip] = useState(false);
  const [showAxesError, setShowAxesError] = useState(false);
  const [showMaximumPositionError, setShowMaximumPositionError] =
    useState(false);

  const raycasterXGriffleistenLeft = new Raycaster();
  raycasterXGriffleistenLeft.far = 20;
  const rayStartXGriffleistenLeft = new Vector3(0, 0, 0);

  const raycasterXGriffleistenRight = new Raycaster();
  raycasterXGriffleistenRight.far = 20;
  const rayStartXGriffleistenRight = new Vector3(0, 0, 0);

  const raycasterSockel = new Raycaster();
  raycasterSockel.far = 20;
  const rayStartSockel = new Vector3(0, 0, 0);

  let heightToCheck;

  if (axes !== "x") {
    heightToCheck = parseFloat(
      boundingBox?.max?.y + sizesMap?.[elementToAdd]?.height + 0.95
    );
  } else {
    heightToCheck = totalHeight;
  }

  let widthToCheck;
  if (axes !== "x") {
    widthToCheck = parseFloat(completeWidth);
  } else {
    widthToCheck = (completeWidth + sizesMap?.[elementToAdd]?.width).toFixed(2);
  }

  // useEffect(() => {
  //   console.log(
  //     elementToAdd,
  //     completeWidth,
  //     sizesMap?.[elementToAdd]?.width,
  //     heightToCheck,
  //     totalHeight
  //   );
  // }, [axes]);

  const calculatePositioning = async () => {
    var additionalHeight = 0;
    if (sizesMap?.[elementToAdd]?.hasEdge) {
      additionalHeight += 0.05;
    }

    if (axes === "x") {
      const raycasterHeights = new Raycaster();
      raycasterHeights.far = 20;
      const rayStartHeights = new Vector3(0, 0, 0);

      rayStartHeights.y = -0.95;
      rayStartHeights.x =
        boundingBox.max.x + sizesMap[elementToAdd].width / 2 - 0.1;
      rayStartHeights.z = -0.5;

      raycasterHeights.ray.origin.copy(rayStartHeights);
      raycasterHeights.ray.direction.set(0, 1, 0);

      var finalUpdatedElementsArray = [];
      var finalUpdatedMaterialsArray = [];

      var finalIndicesToDelete = [];

      // Code for Sockel calculations
      // calculate and shoot raycaster for sockel
      rayStartSockel.y = -0.95;
      rayStartSockel.x = boundingBox.max.x + sizesMap[elementToAdd].width / 2;
      rayStartSockel.z = -0.245;

      raycasterSockel.ray.origin.copy(rayStartSockel);
      raycasterSockel.ray.direction.set(-1, 0, 0);

      const intersectionSockel = raycasterSockel.intersectObjects(
        models.current,
        true
      );

      const [
        indicesSockel,
        matchingSockel,
        correctXpositionOfSockel,
        correctSockel,
      ] = sockelHelper(
        intersectionSockel,
        elementToAdd,
        elements,
        elementsMaterials,
        boundingBox
      );

      const filteredElements = elements.filter((_, index) => {
        return !indicesSockel.includes(index);
      });

      const filteredElementsMaterials = elementsMaterials.filter((_, index) => {
        return !indicesSockel.includes(index);
      });

      if (indicesSockel.length) {
        // Iterate in reverse order to avoid reordering indices
        for (let i = indicesSockel.length - 1; i >= 0; i--) {
          const index = indicesSockel[i];
          const modelToRemove = models.current[index];
          if (modelToRemove) {
            modelToRemove.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                if (child.material.map) {
                  child.material.map.dispose();
                }
                child.material.dispose();
              }
            });
          }
        }
      }

      for (const index of indicesSockel) {
        models.current.splice(index, 1);
      }

      setElementToDelete(indicesSockel);
      // updateModelRefs(models.current);

      if (matchingSockel) {
        setElements(filteredElements);
        setElementsMaterials(filteredElementsMaterials);
      }

      if (correctSockel) {
        setElements((prevElements) => [
          ...prevElements,
          {
            x: boundingBox?.max?.x + sizesMap[elementToAdd].width / 2,
            y: parseFloat(
              boundingBox?.min?.y + sizesMap[elementToAdd].height / 2
            ).toFixed(5),
            z: -0.5,
            model: elementToAdd,
            griffleiste: false,
            sockel: false,
          },
          {
            x: correctXpositionOfSockel,
            y: -0.95,
            z: -0.5,
            model: correctSockel,
            griffleiste: false,
            sockel: true,
          },
        ]);
        setEditMode(false);
        setCompleteWidth(completeWidth + sizesMap[elementToAdd]?.width);
        setHeightControls((prevHeights) => [...prevHeights, raycasterHeights]);
      }
    } else if (axes === "y" || axes === "yLeft") {
      // Code for Griffleisten calculations
      // calculate and shoot raycaster for griffleisten
      rayStartXGriffleistenLeft.y = boundingBox.max.y + 0.01;
      rayStartXGriffleistenLeft.x = boundingBox.min.x + 0.01;
      rayStartXGriffleistenLeft.z = -0.245;

      raycasterXGriffleistenLeft.ray.origin.copy(rayStartXGriffleistenLeft);
      raycasterXGriffleistenLeft.ray.direction.set(-1, 0, 0);

      rayStartXGriffleistenRight.y = boundingBox.max.y + 0.01;
      if (axes === "y") {
        rayStartXGriffleistenRight.x = boundingBox.max.x - 0.01;
      } else if (axes === "yLeft") {
        rayStartXGriffleistenRight.x =
          (boundingBox.max.x + boundingBox.min.x) / 2 - 0.01;
      }

      rayStartXGriffleistenRight.z = -0.245;

      raycasterXGriffleistenRight.ray.origin.copy(rayStartXGriffleistenRight);
      raycasterXGriffleistenRight.ray.direction.set(1, 0, 0);

      const intersectionOnXGriffleistenLeft =
        raycasterXGriffleistenLeft.intersectObjects(models.current, true);

      const leftSum = [];
      const leftObjects = [];
      let continueAccumulationLeft = true;

      let firstLeftBoundingBox = new THREE.Box3();
      let secondLeftBoundingBox = new THREE.Box3();
      let previousObject;
      if (axes === "y") {
        previousObject = models.current[indexOfModel];
      } else if (axes === "yLeft") {
        let placeholderObject = new THREE.Mesh(
          new THREE.BoxGeometry(
            sizesMap?.[elementToAdd].width,
            sizesMap?.[elementToAdd].height,
            0.6
          ),
          new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
        );
        placeholderObject.position.set(
          boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
          boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
          -0.5
        );
        previousObject = placeholderObject;
      }

      raycasterXGriffleistenLeft
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationLeft
          ) {
            // Calculate the sum for each intersection and push it into leftSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObject) {
              firstLeftBoundingBox.setFromObject(previousObject);
              secondLeftBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstLeftBoundingBox.min.x - secondLeftBoundingBox.max.x
              );

              if (distanceX > 0.1) {
                continueAccumulationLeft = false;
              } else {
                // Accumulate data only when the distance is within the limit
                leftSum.push(width);
                leftObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObject = intersect.object;
          } else {
            continueAccumulationLeft = false;
          }
        });

      const rightSum = [];
      const rightObjects = [];
      let continueAccumulationRight = true;

      let firstRightBoundingBox = new THREE.Box3();
      let secondRightBoundingBox = new THREE.Box3();
      let previousObjectRightSide;

      if (axes === "y") {
        previousObjectRightSide = models.current[indexOfModel];
      } else if (axes === "yLeft") {
        let placeholderObject = new THREE.Mesh(
          new THREE.BoxGeometry(
            sizesMap?.[elementToAdd].width,
            sizesMap?.[elementToAdd].height,
            0.6
          ),
          new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
        );
        placeholderObject.position.set(
          boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
          boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
          -0.5
        );
        previousObjectRightSide = placeholderObject;
      }

      raycasterXGriffleistenRight
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationRight
          ) {
            // Calculate the sum for each intersection and push it into rightSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObjectRightSide) {
              firstRightBoundingBox.setFromObject(previousObjectRightSide);
              secondRightBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstRightBoundingBox.max.x - secondRightBoundingBox.min.x
              );

              if (distanceX > 0.1) {
                continueAccumulationRight = false;
              } else {
                // Accumulate data only when the distance is within the limit
                rightSum.push(width);
                rightObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObjectRightSide = intersect.object;
          } else {
            continueAccumulationRight = false;
          }
        });

      const [
        indices,
        matchingModel,
        correctXpositionOfGriffleiste,
        correctGriffleiste,
      ] = griffleistenHelper(
        leftSum,
        leftObjects,
        rightSum,
        rightObjects,
        elementToAdd,
        elements,
        elementsMaterials,
        boundingBox,
        axes
      );

      const filteredElements = elements.filter((_, index) => {
        return !indices.includes(index);
      });

      const filteredElementsMaterials = elementsMaterials.filter((_, index) => {
        return !indices.includes(index);
      });

      if (indices.length) {
        // Iterate in reverse order to avoid reordering indices
        for (let i = indices.length - 1; i >= 0; i--) {
          const index = indices[i];
          const modelToRemove = models.current[index];
          if (modelToRemove) {
            modelToRemove.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                if (child.material.map) {
                  child.material.map.dispose();
                }
                child.material.dispose();
              }
            });
          }
        }
      }

      for (const index of indices) {
        models.current.splice(index, 1);
      }

      setElementToDelete(indices);
      // updateModelRefs(models.current);

      if (matchingModel) {
        setElements(filteredElements);
        setElementsMaterials(filteredElementsMaterials);
      }

      setElements((prevElements) => [
        ...prevElements,
        {
          x: boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
          y:
            boundingBox?.max?.y +
            sizesMap[elementToAdd].height / 2 +
            additionalHeight,
          z: -0.5,
          model: elementToAdd,
        },
        {
          x: correctXpositionOfGriffleiste,
          y: boundingBox?.max?.y + 0.04 - 0.015,
          z: -0.5,
          model: correctGriffleiste,
          griffleiste: true,
          sockel: false,
        },
      ]);
      setEditMode(false);
      setHeightControls((prevHeights) => [...prevHeights]);
    } else if (axes === "yDouble") {
      rayStartXGriffleistenLeft.y = boundingBox.max.y + 0.01;
      rayStartXGriffleistenLeft.x = boundingBox.min.x + 0.01;
      rayStartXGriffleistenLeft.z = -0.245;

      raycasterXGriffleistenLeft.ray.origin.copy(rayStartXGriffleistenLeft);
      raycasterXGriffleistenLeft.ray.direction.set(-1, 0, 0);

      rayStartXGriffleistenRight.y = boundingBox.max.y + 0.01;
      rayStartXGriffleistenRight.x = boundingBox.max.x - 0.01;
      rayStartXGriffleistenRight.z = -0.245;

      raycasterXGriffleistenRight.ray.origin.copy(rayStartXGriffleistenRight);
      raycasterXGriffleistenRight.ray.direction.set(1, 0, 0);

      const intersectionOnXGriffleistenLeft =
        raycasterXGriffleistenLeft.intersectObjects(models.current, true);

      const leftSum = [];
      const leftObjects = [];
      let continueAccumulationLeft = true;

      let firstLeftBoundingBox = new THREE.Box3();
      let secondLeftBoundingBox = new THREE.Box3();
      let previousObject;

      let placeholderObject = new THREE.Mesh(
        new THREE.BoxGeometry(
          sizesMap?.[elementToAdd].width,
          sizesMap?.[elementToAdd].height,
          0.6
        ),
        new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
      );
      placeholderObject.position.set(
        boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
        boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
        -0.5
      );
      previousObject = placeholderObject;

      raycasterXGriffleistenLeft
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationLeft
          ) {
            // Calculate the sum for each intersection and push it into leftSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObject) {
              firstLeftBoundingBox.setFromObject(previousObject);
              secondLeftBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstLeftBoundingBox.min.x - secondLeftBoundingBox.max.x
              );

              if (distanceX > 0.1) {
                continueAccumulationLeft = false;
              } else {
                // Accumulate data only when the distance is within the limit
                leftSum.push(width);
                leftObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObject = intersect.object;
          } else {
            continueAccumulationLeft = false;
          }
        });

      const rightSum = [];
      const rightObjects = [];
      let continueAccumulationRight = true;

      let firstRightBoundingBox = new THREE.Box3();
      let secondRightBoundingBox = new THREE.Box3();
      let previousObjectRightSide = placeholderObject;

      raycasterXGriffleistenRight
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationRight
          ) {
            // Calculate the sum for each intersection and push it into rightSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObjectRightSide) {
              firstRightBoundingBox.setFromObject(previousObjectRightSide);
              secondRightBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstRightBoundingBox.max.x - secondRightBoundingBox.min.x
              );

              if (distanceX > 0.1) {
                continueAccumulationRight = false;
              } else {
                // Accumulate data only when the distance is within the limit
                rightSum.push(width);
                rightObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObjectRightSide = intersect.object;
          } else {
            continueAccumulationRight = false;
          }
        });

      const [
        indices,
        matchingModel,
        correctXpositionOfGriffleiste,
        correctGriffleiste,
      ] = griffleistenHelper(
        leftSum,
        leftObjects,
        rightSum,
        rightObjects,
        elementToAdd,
        elements,
        elementsMaterials,
        boundingBox,
        axes
      );

      const filteredElements = elements.filter((_, index) => {
        return !indices.includes(index);
      });

      const filteredElementsMaterials = elementsMaterials.filter((_, index) => {
        return !indices.includes(index);
      });

      if (indices.length) {
        // Iterate in reverse order to avoid reordering indices
        for (let i = indices.length - 1; i >= 0; i--) {
          const index = indices[i];
          const modelToRemove = models.current[index];
          if (modelToRemove) {
            modelToRemove.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                if (child.material.map) {
                  child.material.map.dispose();
                }
                child.material.dispose();
              }
            });
          }
        }
      }

      for (const index of indices) {
        models.current.splice(index, 1);
      }

      setElementToDelete(indices);
      // updateModelRefs(models.current);

      if (matchingModel) {
        setElements(filteredElements);
        setElementsMaterials(filteredElementsMaterials);
      }

      setElements((prevElements) => [
        ...prevElements,
        {
          x: boundingBox?.min?.x + sizesMap[elementToAdd].width / 2,
          y:
            boundingBox?.max?.y +
            sizesMap[elementToAdd].height / 2 +
            additionalHeight,
          z: -0.5,
          model: elementToAdd,
        },
        {
          x: correctXpositionOfGriffleiste,
          y: boundingBox?.max?.y + 0.04 - 0.015,
          z: -0.5,
          model: correctGriffleiste,
          griffleiste: true,
          sockel: false,
        },
      ]);
      setEditMode(false);
    } else if (axes === "yRight") {
      rayStartXGriffleistenLeft.y = boundingBox.max.y + 0.01;
      rayStartXGriffleistenLeft.x =
        (boundingBox.max.x + boundingBox.min.x) / 2 + 0.01;
      rayStartXGriffleistenLeft.z = -0.245;

      raycasterXGriffleistenLeft.ray.origin.copy(rayStartXGriffleistenLeft);
      raycasterXGriffleistenLeft.ray.direction.set(-1, 0, 0);

      rayStartXGriffleistenRight.y = boundingBox.max.y + 0.01;
      rayStartXGriffleistenRight.x = boundingBox.max.x - 0.01;
      rayStartXGriffleistenRight.z = -0.245;

      raycasterXGriffleistenRight.ray.origin.copy(rayStartXGriffleistenRight);
      raycasterXGriffleistenRight.ray.direction.set(1, 0, 0);

      const intersectionOnXGriffleistenLeft =
        raycasterXGriffleistenLeft.intersectObjects(models.current, true);

      const leftSum = [];
      const leftObjects = [];
      let continueAccumulationLeft = true;

      let firstLeftBoundingBox = new THREE.Box3();
      let secondLeftBoundingBox = new THREE.Box3();

      const placeholderObject = new THREE.Mesh(
        new THREE.BoxGeometry(
          sizesMap?.[elementToAdd].width,
          sizesMap?.[elementToAdd].height,
          0.6
        ),
        new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
      );
      placeholderObject.position.set(
        (boundingBox?.min?.x + boundingBox?.max?.x) / 2 +
          sizesMap[elementToAdd].width / 2,
        boundingBox?.max?.y + sizesMap[elementToAdd].height / 2 + 0.05,
        -0.5
      );

      let previousObject = placeholderObject;

      raycasterXGriffleistenLeft
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationLeft
          ) {
            // Calculate the sum for each intersection and push it into leftSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObject) {
              firstLeftBoundingBox.setFromObject(previousObject);
              secondLeftBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstLeftBoundingBox.min.x - secondLeftBoundingBox.max.x
              );

              if (distanceX > 0.1) {
                continueAccumulationLeft = false;
              } else {
                // Accumulate data only when the distance is within the limit
                leftSum.push(width);
                leftObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObject = intersect.object;
          } else {
            continueAccumulationLeft = false;
          }
        });

      const rightSum = [];
      const rightObjects = [];
      let continueAccumulationRight = true;

      let firstRightBoundingBox = new THREE.Box3();
      let secondRightBoundingBox = new THREE.Box3();
      let previousObjectRightSide = models.current[indexOfModel];

      raycasterXGriffleistenRight
        .intersectObjects(models.current)
        .forEach((intersect) => {
          if (
            intersect?.object?.name === "griffleiste" &&
            intersect?.object?.width + sizesMap?.[elementToAdd]?.width <= 2.7 &&
            continueAccumulationRight
          ) {
            // Calculate the sum for each intersection and push it into rightSum
            const width = intersect.object.width;

            // Check the distance between the current object and the previous object
            if (previousObjectRightSide) {
              firstRightBoundingBox.setFromObject(previousObjectRightSide);
              secondRightBoundingBox.setFromObject(intersect.object);

              const distanceX = Math.abs(
                firstRightBoundingBox.max.x - secondRightBoundingBox.min.x
              );

              if (distanceX > 0.1) {
                continueAccumulationRight = false;
              } else {
                // Accumulate data only when the distance is within the limit
                rightSum.push(width);
                rightObjects.push(intersect);
              }
            }

            // Update the previousObject for the next iteration
            previousObjectRightSide = intersect.object;
          } else {
            continueAccumulationRight = false;
          }
        });

      const [
        indices,
        matchingModel,
        correctXpositionOfGriffleiste,
        correctGriffleiste,
      ] = griffleistenHelper(
        leftSum,
        leftObjects,
        rightSum,
        rightObjects,
        elementToAdd,
        elements,
        elementsMaterials,
        boundingBox,
        axes
      );

      const filteredElements = elements.filter((_, index) => {
        return !indices.includes(index);
      });

      const filteredElementsMaterials = elementsMaterials.filter((_, index) => {
        return !indices.includes(index);
      });

      if (indices.length) {
        // Iterate in reverse order to avoid reordering indices
        for (let i = indices.length - 1; i >= 0; i--) {
          const index = indices[i];
          const modelToRemove = models.current[index];
          if (modelToRemove) {
            modelToRemove.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                if (child.material.map) {
                  child.material.map.dispose();
                }
                child.material.dispose();
              }
            });
          }
        }
      }

      for (const index of indices) {
        models.current.splice(index, 1);
      }

      setElementToDelete(indices);
      // updateModelRefs(models.current);

      if (matchingModel) {
        setElements(filteredElements);
        setElementsMaterials(filteredElementsMaterials);
      }

      setElements((prevElements) => [
        ...prevElements,
        {
          x:
            (boundingBox?.min?.x + boundingBox?.max?.x) / 2 +
            sizesMap[elementToAdd].width / 2,
          y:
            boundingBox?.max?.y +
            sizesMap[elementToAdd].height / 2 +
            additionalHeight,
          z: -0.5,
          model: elementToAdd,
        },
        {
          x: correctXpositionOfGriffleiste,
          y: boundingBox?.max?.y + 0.04 - 0.015,
          z: -0.5,
          model: correctGriffleiste,
          griffleiste: true,
          sockel: false,
        },
      ]);
      setEditMode(false);
    } else if (axes === "start") {
      const raycasterHeights = new Raycaster();
      raycasterHeights.far = 20;
      const rayStartHeights = new Vector3(0, 0, 0);

      rayStartHeights.y = -0.95;
      rayStartHeights.x = -1.1;
      rayStartHeights.z = -0.5;

      raycasterHeights.ray.origin.copy(rayStartHeights);
      raycasterHeights.ray.direction.set(0, 1, 0);

      const fixedModelWidth = Number(sizesMap[elementToAdd]?.width.toFixed(2));

      const matchingSockel = Object.keys(sizesMap).find((modelName) => {
        return (
          modelName.startsWith("Model_15") &&
          sizesMap[modelName].width === fixedModelWidth
        );
      });

      setElements((prevElements) => [
        ...prevElements,
        {
          x: -1,
          y: parseFloat(
            -0.95 + sizesMap[elementToAdd]?.height / 2 + 0.04 - 0.015
          ).toFixed(5),
          z: -0.5,
          model: elementToAdd,
          griffleiste: false,
          sockel: false,
        },
        {
          x: -1,
          y: -0.95,
          z: -0.5,
          model: matchingSockel,
          griffleiste: false,
          sockel: true,
        },
      ]);
      setEditMode(false);
      setCompleteWidth(sizesMap[elementToAdd]?.width);
      setHeightControls((prevHeights) => [...prevHeights, raycasterHeights]);
    }

    if (axes === "x") {
      sizesMap[elementToAdd]?.hasFront
        ? setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: globalFrontenTexture,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalSockelTexture,
              griffleiste: false,
              sockel: true,
              wange: false,
            },
          ])
        : setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: null,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalSockelTexture,
              griffleiste: false,
              sockel: true,
              wange: false,
            },
          ]);
    } else if (axes === "start") {
      sizesMap[elementToAdd]?.hasFront
        ? setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: globalFrontenTexture,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalSockelTexture,
              griffleiste: false,
              sockel: true,
              wange: false,
            },
          ])
        : setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: null,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalSockelTexture,
              griffleiste: false,
              sockel: true,
              wange: false,
            },
          ]);
    } else {
      sizesMap[elementToAdd]?.hasFront
        ? setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: globalFrontenTexture,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalGriffleistenTexture,
              griffleiste: true,
              sockel: false,
              wange: false,
            },
          ])
        : setElementsMaterials((prevElementsMaterials) => [
            ...prevElementsMaterials,
            {
              front: null,
              korpus: globalKorpusTexture,
              griffleiste: false,
              sockel: false,
              wange: false,
            },
            {
              front: null,
              korpus: globalGriffleistenTexture,
              griffleiste: true,
              sockel: false,
              wange: false,
            },
          ]);
    }
  };

  const handleAddElement = async () => {
    setShowSidebar(false);
    await calculatePositioning();
    setShowAxesModeX(false);
    setShowAxesModeY(false);
    setShowAxesModeYleft(false);
    setShowAxesModeYright(false);
    setShowAxesModeYdouble(false);
    setIndexOfModel(null);
    setBoundingBox({
      max: [0, 0, 0],
      min: [0, 0, 0],
    });
    setShowCabinets(true);
    setShowShelves(true);
    setShowDrawers(true);
    setElementsRendering(elements);
    setShowXBottomTip(false);
  };

  useEffect(() => {
    if (elementAdded && axes === null) {
      setShowAxesError(true);
      setElementToAdd("");
      setElementAdded("");
    } else {
      if (
        elementToAdd &&
        widthToCheck < 8.3 &&
        (heightToCheck < 3.3 ||
          totalHeight === undefined ||
          totalHeight == 0) &&
        axes !== "yRight"
      ) {
        setLoading(true);
        handleAddElement();
      } else if (axes === "yRight") {
        if (
          sizesMap?.[elementToAdd]?.width >=
            sizesMap?.[elements?.[indexOfModel]?.model]?.width &&
          (spaceUnderModel > 0.09 || spaceUnderModel === undefined)
        ) {
          setShowXBottomTip(true);
          setElementAdded("");
        } else {
          setLoading(true);
          handleAddElement();
        }
      } else if (
        elementToAdd &&
        elementAdded &&
        (widthToCheck > 8.3 || heightToCheck > 3.3)
      ) {
        setShowMaximumPositionError(true);
      }
    }
  }, [elementAdded]);

  return (
    <>
      {/* <div className={styles.buttonwrapper}>
        <button
          onClick={() => {
            setEditMode(false);
            setShowAxesModeX(false);
            setShowAxesModeY(false);
            setShowAxesModeYleft(false);
            setShowAxesModeYright(false);
            setShowAxesModeYdouble(false);
            setIndexOfModel(null);
            setBoundingBox({
              max: [0, 0, 0],
              min: [0, 0, 0],
            });
          }}
          className={`${btnStyles.closeEditMode} ${stylesConfigurator.pointer}`}
        >
          Editermodus verlassen
        </button>

        {elementToAdd &&
          widthToCheck < 8.3 &&
          (heightToCheck < 3.3 ||
            totalHeight === undefined ||
            totalHeight == 0) && (
            <button
              className={`${btnStyles.addElement} ${stylesConfigurator.pointer}`}
              onClick={() => handleAddElement()}
            >
              Element hinzufügen
            </button>
          )}
      </div> */}

      <Alert show={showAxesError} variant="danger">
        <Alert.Heading
          className={`${btnStyles.alertButton} ${stylesConfigurator.pointer}`}
          onClick={() => setShowAxesError(false)}
        >
          <p>Hinzufügen nicht möglich</p>
          <img
            src={
              process.env.PUBLIC_URL + "/images/icons/circle-minus-solid.svg"
            }
            variant="outline-success"
          />
        </Alert.Heading>
        <p className={btnStyles.alertParagraph}>
          Bitte wähle aus, wohin das Element gesetzt werden soll, indem du auf
          ein 3D-Modul klickst.
        </p>
        <hr />
        <div className="d-flex justify-content-end"></div>
      </Alert>

      <Alert show={showMaximumPositionError} variant="danger">
        <Alert.Heading
          className={`${btnStyles.alertButton} ${stylesConfigurator.pointer}`}
          onClick={() => setShowMaximumPositionError(false)}
        >
          <p>Hinzufügen nicht möglich</p>
          <img
            src={
              process.env.PUBLIC_URL + "/images/icons/circle-minus-solid.svg"
            }
            variant="outline-success"
          />
        </Alert.Heading>
        <p className={btnStyles.alertParagraph}>
          Die Auswahl überschreitet die Maximalhöhe von 3.28m, oder die
          Maximalbreite von 8.10m. Bitte wähle eine andere Konstellation.
        </p>
        <hr />
        <div className="d-flex justify-content-end"></div>
      </Alert>

      <Alert show={showXBottomTip} variant="danger">
        <Alert.Heading
          className={`${btnStyles.alertButton} ${stylesConfigurator.pointer}`}
          onClick={() => setShowXBottomTip(false)}
        >
          <p>Hinzufügen nicht möglich</p>
          <img
            src={
              process.env.PUBLIC_URL + "/images/icons/circle-minus-solid.svg"
            }
            variant="outline-success"
          />
        </Alert.Heading>
        <p className={btnStyles.alertParagraph}>
          Bitte stelle sicher, dass sich kein Hohlraum unter dem zu setzenden
          Modul befindet!
        </p>
        <hr />
        <div className="d-flex justify-content-end"></div>
      </Alert>
    </>
  );
};

export default SidebarElements;

import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useGlobalContext } from "../../contextProviders/ContextProvider";
import modelMap from "./modelMap";

const ElementsDisplay = ({ modelRefs }) => {
  const {
    elements,
    setElementToAdd,
    indexOfModel,
    setIndexOfModel,
    setAxes,
    setLabelPosition,
  } = useGlobalContext();

  // Use useCallback to create a filtered version of modelRefs.current
  const updateModelRefs = useCallback(() => {
    modelRefs.current = modelRefs.current.filter((ref) => ref !== null);
  }, [modelRefs.current]);

  // Call updateModelRefs when indexOfModel changes
  React.useEffect(() => {
    updateModelRefs();
  }, [indexOfModel, updateModelRefs]);

  const handlePosition = (e) => {
    const { clientX, clientY } = e;

    let widthToCheck;
    if (window.innerWidth > 1400) {
      let sidebarWidth = (window.innerWidth / 100) * 33;
      widthToCheck = window.innerWidth - sidebarWidth;
    } else {
      widthToCheck = window.innerWidth;
    }

    let minX = 100; // Minimum X coordinate
    let maxX = widthToCheck; // Maximum X coordinate
    let minY = 0 + 200; // Minimum Y coordinate
    let maxY = window.innerHeight; // Maximum Y coordinate

    if (window.innerWidth > 1400) {
      minX += 350;
      maxX -= 350;
    } else {
      maxX -= 200;
      maxY -= 350;
    }

    const clampedX = Math.max(minX, Math.min(maxX, clientX));
    const clampedY = Math.max(minY, Math.min(maxY, clientY));

    const positions = { x: clampedX, y: clampedY };
    setLabelPosition(positions);
  };

  return (
    <>
      <group>
        {elements?.map(
          (element, index) =>
            index !== null &&
            element !== null && (
              <mesh
                key={index}
                position={[element.x, element.y, element.z]}
                onClick={(e) => {
                  setIndexOfModel(index);
                  setElementToAdd("");
                  handlePosition(e);
                  setAxes(null);
                  e.stopPropagation();
                }}
                ref={(ref) => (modelRefs.current[index] = ref)}
              >
                {React.cloneElement(modelMap[element.model], { index })}
              </mesh>
            )
        )}
      </group>
    </>
  );
};

export default ElementsDisplay;
